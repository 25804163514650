export const GET_EVENTS = 'GET_EVENTS'
export const SET_EVENTS = 'SET_EVENTS'
export const REFRESH_EVENTS = 'REFRESH_EVENTS'
export const SET_ANCHOR = 'SET_ANCHOR'
export const QUERY_EVENTS = 'QUERY_EVENTS'
export const CLEAR_EVENTS = 'CLEAR_EVENTS'
export const INITIALIZE = 'INITIALIZE'
export const REINITIALIZE = 'REINITIALIZE'

const getEvents = (pageToken, direction, calendar, isInitialLoad) => ({
    type: GET_EVENTS,
    payload: {
        pageToken: pageToken,
        direction: direction,
        calendarId: calendar,
        isInitialLoad: isInitialLoad
    }
})

const queryEvents = (searchString, calendar) => ({
    type: QUERY_EVENTS,
    payload: {
        searchString: searchString,
        calendarId: calendar,
    }
})

const setEvents = (events, direction, searchString) => ({

    type: SET_EVENTS,
    payload: events,
    direction: direction,
    searchString: searchString
})


const refreshEvents = (position) => ({
    type: REFRESH_EVENTS,
    position: position
})

const setAnchor = (index, position) => ({
    type: SET_ANCHOR,
    anchor: index,
    position: position
})

const clearEvents = () => ({
    type: CLEAR_EVENTS
})

const initialize = (initialized) => ({
    type: INITIALIZE,
    initialize: initialized
})

const reInitializeEvents = () => ({
    type: REINITIALIZE
})

export {
    getEvents,
    setEvents,
    refreshEvents,
    setAnchor,
    queryEvents,
    clearEvents,
    initialize,
    reInitializeEvents
}
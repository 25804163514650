import { apiService, HttpError } from '../../shared'

export const fetchProfile = async (userId) => {
    const { data, ok, status } = await apiService.get(`accounts/${ userId }`)

    verifyOk(ok, status)

    return { contact: {}, ...data }
}

export const changePassword = async (oldPassword, newPassword) => {
    const { data, status } = await apiService.post('account/changepassword', { oldPassword, newPassword })

    return { data, status }
}

export const resendConfirmationEmail = async (email) => {
    const { ok, status } = await apiService.get('account/resendEmailConfirmation',
        { email: encodeURIComponent(email) })

    verifyOk(ok, status)

    return ok
}


const verifyOk = (ok, status) => {
    if (!ok) {
        throw new HttpError(status)
    }
}
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import i18n from '../../../i18n'
import styled from 'styled-components'
import ProfileProvider, { useProfile } from './ProfileProvider'
import { Container, Grid, Divider, Avatar, Tooltip, Typography } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import { Loader, TopBar } from '../../shared'
import CustomTextField from './CustomTextField'
import ChangePasswordModal from '../components/ChangePasswordModal'

const ProfileScreen = () => {
  const { profile, isLoading, logout, resendConfEmail } = useProfile()
  const [isChangePassModalOpened, setIsChangePassModalOpened] = useState(false)
  const { t } = useTranslation('', i18n)
  const isProfileActive = profile?.status === 'active'

  const ProfileContent = () => {
    const { linkedIn, facebook, phoneNumber } = profile.contact

    return <BodyContainer spacing={ 0 }>
      <LeftContainer item xs={ 12 } md={ 6 } lg={ 6 }>
        <UserAvatar data-test-id='profile-avatar' src={ profile.imageLink } />
        <h2 data-test-id='profile-name'>{ profile.name }</h2>

        <CategoryContainer>
          <CategoryTitle data-test-id='profile-about-me'>{ t('profile.sections.aboutMe') }</CategoryTitle>
          <CategoryData data-test-id='profile-description'>
            {
              profile.description ? profile.description : <>{ t('emptyMessages.profileAbout') }</>
            }
          </CategoryData>
        </CategoryContainer>

        <CategoryContainer container direction='column'>
          <CategoryTitle data-test-id='profile-user-details' >
            { t('profile.sections.userDetails') } </CategoryTitle>
          <CustomTextField
            label='Email'
            defaultValue={ profile.email }
          />
          { !isProfileActive && <ResendConfirmation
            onClick={ resendConfEmail }
            data-test-id='resend-confirmation-email'>
            <span>{ t('profile.notVerified') }</span>
            <span>{ t('profile.resendConfirmation') }</span>
          </ResendConfirmation> }
          <CustomTextField
            label={ t('profile.properties.phone') }
            defaultValue={ phoneNumber }
            placeholder={ t('profile.properties.empty.phone') }
          />
        </CategoryContainer>

        <CategoryContainer container direction='column'>
          <CategoryTitle data-test-id='profile-social-profiles'>
            { t('profile.sections.socialProfiles') } </CategoryTitle>
          <CustomTextField
            label='LinkedIn'
            defaultValue={ linkedIn }
            placeholder={ t('profile.properties.empty.LinkedIn') }
          />
          <CustomTextField
            label='Facebook'
            defaultValue={ facebook }
            placeholder={ t('profile.properties.empty.Facebook') }
          />
        </CategoryContainer>

        { !profile.socialId && <Tooltip
          disableFocusListener={ isProfileActive }
          disableHoverListener={ isProfileActive }
          disableTouchListener={ isProfileActive }
          title={ t('profile.changePassword') }
          placement='top'>
          <ButtonWrapper>
            <ActionButton
              disabled={ profile.status !== 'active' }
              fullWidth
              variant='outlined'
              color='primary'
              onClick={ () => setIsChangePassModalOpened(true) }
              data-test-id='profile-changePassword-btn'>
              { t('button.changePassword') }
            </ActionButton>
          </ButtonWrapper>
        </Tooltip> }
        <ActionButton
          fullWidth
          variant='outlined'
          color='primary'
          onClick={ logout }
          data-test-id='profile-logout-btn'
        >{ t('button.logout') }
        </ActionButton>
      </LeftContainer>

      <ChangePasswordModal open={ isChangePassModalOpened } setOpen={ setIsChangePassModalOpened } />
    </BodyContainer>
  }

  return <StyledGrid
    container
    direction='column'
    justify='center'
    alignItems='center'
  >
    <TopBar data-test-id='profile-title' name={ t('screenTitle.profile') } hideActionButton hideSearchBar />

    <Container data-test-id='divider-wrapper'>
      <StyledDivider />
    </Container>

    { isLoading ? <Loader /> : <ProfileContent /> }

  </StyledGrid>
}


const StyledGrid = styled(Grid)`
  padding-bottom: 10px;
`

const LeftContainer = styled(Grid)`
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const ResendConfirmation = styled(Typography)`
  align-self: flex-end;
  cursor: pointer;
  font-size: 13px;
  margin-right: 6px;
  
  span {
    color: ${({ theme }) => theme.palette.primary.main };

    &:first-child {
      color: ${({ theme }) => theme.palette.error.main };
    }
  }
`

const BodyContainer = styled(Container)`
  overflow: hidden;
  padding: 0px 16px;
`

const CategoryContainer = styled(Grid)`
  width: 100%;
  padding-bottom: 16px;
`

const CategoryData = styled(Grid)`
  border-radius: 4px;
  padding: 6px 6px 6px 12px;
  margin: 6px 6px 0 0;
  flex-wrap: nowrap;
  background: ${ ({ theme }) => theme.palette.background.default };
  word-break: break-word;
`

const CategoryTitle = styled.div`
  padding-left: 12px;
  font-weight: 700;
  text-transform: uppercase;
  color: ${ ({ theme }) => theme.palette.secondary.main };
`

const UserAvatar = styled(Avatar)`
  width: 128px;
  height: 128px;
`

const ButtonWrapper = styled.span`
  width: 100%;
`

const ActionButton = styled(Button)`
  background-color: ${ ({ theme }) => theme.palette.background.paper };
  margin-top: 8px;
  margin-bottom: 12px;
`

const StyledDivider = styled(Divider)`
  margin-bottom: 28px;
  color: ${ ({ theme }) => theme.palette.background.default };
`

export default () => {
  return <>
    <ProfileProvider>
      <ProfileScreen />
    </ProfileProvider>
  </>
}
import React, { createContext, useState, useEffect, useContext, useRef } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import useContacts from '../hooks/contactsHook'
import { ConversationsContext } from './ConversationsManager'

const contactsPerPage = 20

const ContactsManager = ({ children }) => {
    const [contacts, setContacts] = useState([])
    const [contactsLoaded, setContactsLoaded] = useState(0)
    const [isSearching, setIsSearching] = useState(false)
    const [isDataLoading, setIsDataLoading] = useState(false)
    const [searchString, setSearchString] = useState('')
    const [hasMoreContacts, setHasMoreContacts] = useState(false)
    const [isEmailSearching, setIsEmailSearching] = useState(false)

    const { contactsView, openedConversation } = useContext(ConversationsContext)
    const { getChatContacts } = useContacts()
    const refContactsLoaded = useRef()
    const refContacts = useRef()
    const refHasMoreContacts = useRef()
    const [isRefreshInProgress, setIsRefreshInProgress] = useState(false)

    const refreshContacts = async (selectedUsers, searchString, shouldRefreshList) => {
        const isSearchStringValid = searchString.length >= 4

        setIsSearching(isSearchStringValid)
        if (!isSearchStringValid) {
            resetContacts()
            return
        }

        setIsDataLoading(true)
        setIsRefreshInProgress(true)

        let contactsResponse = await getChatContacts(searchString, shouldRefreshList ? 0 : refContactsLoaded.current)
        if (contactsResponse.error) {
            contactsResponse = []
        }

        setHasMoreContacts(contactsResponse.length === contactsPerPage)
        setContactsLoaded(prevState => shouldRefreshList ? contactsResponse.length : prevState + contactsResponse.length)
        const updatedContacts = shouldRefreshList ? contactsResponse : refContacts.current.concat(contactsResponse)

        filterContacts(updatedContacts, selectedUsers, searchString)

        setIsSearching(false)
        setIsDataLoading(false)
    }

    const removeAlreadySelectedContacts = (contactsCollection = contacts, selectedUsers) => {
        return selectedUsers ? _.filter(contactsCollection, contact =>
            !_.find(selectedUsers, { userId: contact.userId })) : contactsCollection
    }

    const filterContacts = (contacts, selectedUsers, searchString) => {
        let filteredContacts = removeAlreadySelectedContacts(contacts, selectedUsers)
        if (contactsView === 'addParticipants') {
            _.remove(filteredContacts, contact =>
                !!_.find(openedConversation.participants, { userId: contact.userId }))
        }

        if (filteredContacts.length > 0 && filteredContacts.length < 20 && hasMoreContacts) {
            // make sure we have continuous list of contacts
            refreshContacts(selectedUsers, searchString, false)
        }

        let blockedUsers = _.remove(filteredContacts, contact => contact.isBlocked)
        blockedUsers = _.sortBy(blockedUsers, user => user.name.toLowerCase())
        setContacts(_.concat([], filteredContacts, blockedUsers))
        setIsRefreshInProgress(false)
    }

    const resetContacts = () => {
        setContacts([])
        setContactsLoaded(0)
        setHasMoreContacts(false)
    }

    useEffect(() => {
        refContactsLoaded.current = contactsLoaded
    }, [contactsLoaded])

    useEffect(() => {
        refContacts.current = contacts
    }, [contacts])

    useEffect(() => {
        refHasMoreContacts.current = hasMoreContacts
    }, [hasMoreContacts])

    return <ContactsProvider value={{
        isDataLoading,
        hasMoreContacts,
        searchString,
        setSearchString,
        isSearching,
        contacts,
        setContacts,
        resetContacts,
        filterContacts,
        refreshContacts,
        isRefreshInProgress,
        setIsRefreshInProgress,
        isEmailSearching,
        setIsEmailSearching
    }}>{children}</ContactsProvider>
}

ContactsManager.propTypes = {
    children: PropTypes.object
}

export const ContactsContext = createContext({
    isDataLoading: false,
    hasMoreContacts: false,
    searchString: '',
    setSearchString: () => { },
    isSearching: false,
    contacts: [],
    setContacts: () => { },
    resetContacts: () => { },
    filterContacts: () => { },
    refreshContacts: () => { }
})

const ContactsProvider = ContactsContext.Provider

export default ContactsManager
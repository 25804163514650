import React, { createContext, useContext, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'

import { fetchCalendars } from '../calendars/'
import { useAccount } from '../account/'

const ResolverContext = createContext({})

const ResolverProvider = ({ children }) => {
    const dispatch = useDispatch()
    const { account } = useAccount()
    const calendars = useSelector(state => state.calendars.items)
    const [ personalCalendar, setPersonalCalendar ] = useState({})

    useEffect(() => {
        if (!account) {
            return
        }
        dispatch(fetchCalendars())
    }, [ account, dispatch ])

    useEffect(() => {
        calendars.length &&
            setPersonalCalendar(calendars.find(cal => cal.isPersonal))
    }, [ calendars, personalCalendar ])

    const fetchMyCalendars = () => dispatch(fetchCalendars()) // TODO this should be with delta updates.
    const findCalendar = calendarId => calendars.find(item => item.calendarId === calendarId)

    return (
        <ResolverContext.Provider
            value={ {
                calendars, personalCalendar,
                findCalendar, fetchMyCalendars
            } }>
            { children }
        </ResolverContext.Provider>
    )
}

ResolverProvider.propTypes = {
    children: PropTypes.object
}

export const useResolver = () => useContext(ResolverContext)
export default ResolverProvider
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import i18n from '../../../i18n'
import { Button, Container, Typography, Box, useMediaQuery } from '@material-ui/core'
import { ArrowBack } from '@material-ui/icons'
import { useHistory, Prompt } from 'react-router-dom'
import ConfirmationDialog from './ConfirmationDialog'

const CreateEditTopBar = ({ name, saveChanges, hasChanged, option, type }) => {
    const history = useHistory()
    const { t } = useTranslation('', i18n)
    const [isDialogOpen, setIsDialogOpen] = useState(false)
    const leavingMessage = t('leavingMessage')

    const xs = useMediaQuery(theme => theme.breakpoints.down('xs'))

    const openDialog = () => {
        setIsDialogOpen(true)
    }
    const closeDialog = () => {
        setIsDialogOpen(false)
    }

    const goBack = e => {
        e.preventDefault()
        history.goBack()
    }

    const backHandler = e => {
        hasChanged ? openDialog() : goBack(e)
    }

    if (hasChanged && !isDialogOpen) {
        window.onbeforeunload = () => true
    } else {
        window.onbeforeunload = undefined
    }

    return <TopBarWrapper>
        <Content>
            <BackBtn onClick={ backHandler } data-test-id='back-arrow' />
            <ConfirmationDialog
                open={ isDialogOpen }
                title={ leavingMessage }
                onClose={ closeDialog }
                confirm={ goBack }
            />

            { !isDialogOpen && <Prompt
                when={ hasChanged }
                message={ leavingMessage }
            /> }

            <PageTitle variant='h5' data-test-id='create-edit-topbar-title'>{ name }</PageTitle>
        </Content>

        <Buttons xs={ xs ? 1 : 0 }>
            <StyledButton
                color='primary'
                onClick={ backHandler }
                variant='outlined'
                data-test-id='cancel-btn'
            >
                { t('button.cancel') }
            </StyledButton>
            <StyledButton
                color='primary'
                onClick={ saveChanges }
                variant='contained'
                data-test-id={ `${ option }-btn` }
                type={ type }
            >
                { t(`button.${ option }`) }
            </StyledButton>
        </Buttons>
    </TopBarWrapper>
}

const TopBarWrapper = styled(Container)`
    align-items: center;
    background-color: white;
    display: flex;
    justify-content: space-between;
    left: auto;
    position: sticky;
    z-index: 1;
    padding-bottom: 20px;
    padding-top: 20px;
    right: 0;
    top: 0;
    z-index: 1;
`

const BackBtn = styled(ArrowBack)`
    &: hover {
        cursor: pointer;
    }
`
const PageTitle = styled(Typography)`
    font-weight: 700;
    margin-left: 22px;
`

const StyledButton = styled(Button)`
    padding: 0px 10px;
    text-transform: none;
`

const Content = styled(Box)`
    display:flex;
    align-items: center;
`

const Buttons = styled(Content)`
    display:${({ xs }) => xs ? 'none' : 'block' };
    ${ StyledButton }:last-of-type {
    margin-left: 20px;
    }
`

CreateEditTopBar.propTypes = {
    name: PropTypes.string,
    saveChanges: PropTypes.func,
    hasChanged: PropTypes.bool,
    option: PropTypes.string,
    type: PropTypes.string
}

export default CreateEditTopBar
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Pusher } from '../../sockets'

import i18n from '../../../i18n'
import styled from 'styled-components'
import { useHistory } from 'react-router'
import debounce from '../../shared/hooks/debounceHook'
import Grid from '@material-ui/core/Grid'
import { CalendarList, fetchCalendars } from '../'
import { TopBar, Loader } from '../../shared'

const MyCalendarsScreen = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { t } = useTranslation('', i18n)

  const calendars = useSelector(state => state.calendars.items)
  const areCalendarsLoading = useSelector(state => state.calendars.isLoading)

  const [filteredCalendars, setFilteredCalendars] = useState(calendars)
  const [searchString, setSearchString] = useState('')

  const filterByName = (string, collection) =>
    collection.filter(elem => elem.name.toLowerCase().indexOf(string.toLowerCase()) !== -1)

  const requestUpdate = useCallback(() => {
    dispatch(fetchCalendars())
  }, [dispatch])

  useEffect(() => {
    Pusher.bind('calendar', requestUpdate)
    Pusher.bind('calendarLink', requestUpdate)
    return () => {
      Pusher.unbind(null, requestUpdate)
    }
  }, [requestUpdate])

  const updateCalendarsList = useCallback((calendars, searchString) => {
    if (searchString.length === 0) {
      setFilteredCalendars(calendars)
      return
    }

    if (searchString.length < 4) {
      return
    }
    setFilteredCalendars(filterByName(searchString, calendars))
  }, [])

  useEffect(() => {
    updateCalendarsList(calendars, searchString)
  }, [updateCalendarsList, searchString, calendars])

  const searchCalendars = debounce(ev => {
    const searchString = ev.target.value
    setSearchString(searchString)

    updateCalendarsList(calendars, searchString)
  }, 500)



  return <CalendarsContent
    container
    direction='column'
    justify='center'
    alignItems='center'
  >
    <TopBar
      name={t('screenTitle.calendars')}
      createCallback={e => history.push('/calendar/create')}
      searchPlaceholder={t('placeholder.calendars')}
      searchCallback={event => {
        event.persist()
        searchCalendars(event)
      }}
    />
    {areCalendarsLoading ? <Loader /> : <CalendarList calendars={filteredCalendars} />}
  </CalendarsContent>
}

const CalendarsContent = styled(Grid)`
  display: flex;
`

export default MyCalendarsScreen
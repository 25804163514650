import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'

import moment from 'moment'
import useDates from './datesHook'
import { useResolver } from '../../config/'
import { isEmpty } from '../../shared/'

const useEvent = (defaultValues, openedCalendarId) => {
    const { startDate, endDate } = useDates()
    const { personalCalendar } = useResolver()
    const calendars = useSelector(state => state.calendars.items)

    const localTimezone = moment.tz.guess()
    const eventInit = {
        occurrenceDate: moment().toISOString(),
        occurrenceId: `undefined-${ moment().format('YYYY-MM-DD') }`,
        name: '',
        isAllDay: false,
        isMultiDay: false,
        startDate: moment(startDate),
        endDate: moment(endDate),
        hexColor: '4256c1',
        startTimezone: localTimezone,
        endTimezone: localTimezone,
        originatingCalendar: {},
        attachments: [{
            type: 'details',
            data: {}
        }],
        location: { radius: 50, autoAttendance: false },
        calendarId: '',
        kind: 'classic',
        rsvpType: 1,
        tags: [],
        colorSettings: {
            type: 'dynamic'
        },
        extendedProperties: {
            shouldHideDates: false,
            shouldHideLocation: false
        }
    }

    const fillInEventValues = values => !isEmpty(values) ? {
        ...values,
        startDate: values.isAllDay ? moment(values.startDate).utc() : moment(values.startDate).tz(values.startTimezone),
        endDate: values.isAllDay ? moment(values.endDate).utc() : moment(values.endDate).tz(values.endTimezone),
        originatingCalendar: values.originatingCalendar,
        originatingCalendarId: values.originatingCalendarId
    } : {}

    const [event, setEvent] = useState({
        ...eventInit,
        ...fillInEventValues(defaultValues)
    })

    //TODO: Remove after the events list logic merge
    useEffect(() => {
        if (!isEmpty(event.originatingCalendar)) {
            return
        }

        if (openedCalendarId) {
            const calendar = calendars.find(cal => cal.calendarId === openedCalendarId)
            if (calendar) {
                setEvent({ ...event, originatingCalendar: calendar, originatingCalendarId: calendar.calendarId })
                return
            }
        }

        if (!isEmpty(personalCalendar)) {
            setEvent({
                ...event,
                originatingCalendarId: personalCalendar.calendarId,
                originatingCalendar: personalCalendar
            })
        }
    }, [openedCalendarId, calendars, event, personalCalendar])

    return {
        event: {
            ...eventInit,
            ...event
        },
        setEvent
    }
}

export default useEvent
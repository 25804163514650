export const GET_EVENT_DETAILS = 'GET_EVENT_DETAILS'
export const SET_EVENT_DETAILS = 'SET_EVENT_DETAILS'
export const REFRESH_EVENT_DETAILS = 'REFRESH_EVENT_DETAILS'

const getEventDetails = (eventId) => ({
    type: GET_EVENT_DETAILS,
    payload: {
        eventId: eventId
    }
})

const setEventDetails = (event) => ({
    type: SET_EVENT_DETAILS,
    payload: event
})

const refreshEventDetails = () => ({
    type: REFRESH_EVENT_DETAILS
})

export {
    getEventDetails,
    setEventDetails,
    refreshEventDetails
}
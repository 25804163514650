import React from 'react'
import { Redirect, Route, Switch } from 'react-router'
import { useHistory, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import i18n from '../../i18n'

import {
    Hidden, useMediaQuery, Button, Box,
    BottomNavigation, BottomNavigationAction
} from '@material-ui/core'
import { getClassicAppUrl } from '../shared'
import { SUPPORT_EMAIL } from '../shared/constants'
import { AllCalLogoIcon } from '../shared/assets/AllCalLogo'
import { useAccount } from '../account'
import Badge from '@material-ui/core/Badge'
import NavigatorProvider, { useNavigation } from './NavigatorProvider'

const Navigator = () => {
    const location = useLocation()
    const history = useHistory()
    const { t } = useTranslation('', i18n)
    const { account } = useAccount()
    const isChat = location.pathname.includes('/chat')
    const { routes, isNavigationTabActive } = useNavigation()

    const hasBottomBar = useMediaQuery(theme => theme.breakpoints.down('sm'))

    const changePage = page => e => {
        e.preventDefault()
        history.push({
            pathname: page,
            state: {
                from: location.pathname
            }
        })
    }

    const makeSideBarItem = item => {
        const isItemActive = isNavigationTabActive(item)

        return item.icon &&
            <SideBarItem
                active={ isItemActive ? 1 : 0 }
                data-test-id={ item.title.toLowerCase() }
                onClick={ changePage(item.path) }
                key={ item.path }
            >
                <Badge variant='dot' color='primary' invisible={ !item.showDot }>
                    { isItemActive ? item.active : item.icon }
                </Badge>
                <span>{ t(`navigator.${ item.title.toLowerCase() }`) }</span>
            </SideBarItem>
    }

    const SideBar = () => {

        return <SideBarWrapper>
            <LogoWrapper>
                <AllCalLogoIcon width={ 138 } height={ 56 } />
            </LogoWrapper>
            { routes.map(item => makeSideBarItem(item)) }

            <ButtonContainer hasbottombar={ hasBottomBar }>
                <ActionButton
                    data-test-id='switch-classic-btn'
                    fullWidth
                    variant='outlined'
                    color='primary'
                    href={ `${ getClassicAppUrl() }/auto-login/${ account.loginToken }` }
                > { t('button.switch') }
                </ActionButton>
                <ActionButton
                    data-test-id='feedback-btn'
                    fullWidth
                    variant='outlined'
                    color='primary'
                    href={ `mailto:${ SUPPORT_EMAIL }?subject=Allcal Feedback` }
                    target='_blank'
                    rel='noopener noreferrer'
                >
                    { t('button.feedback') }
                </ActionButton>
            </ButtonContainer>
        </SideBarWrapper>
    }

    const makeBottomBarItem = (item) => {
        const isItemActive = isNavigationTabActive(item)

        return item.icon &&
            <StyledBottomNavigationAction
                key={ item.path }
                badge={ 1 }
                data-test-id={ item.title.toLowerCase() }
                icon={ <Badge variant='dot' color='primary'
                    invisible={ !item.showDot }>{ isItemActive ? item.active : item.icon }</Badge> }
                active={ isItemActive ? 1 : 0 }
                value={ item.path }
                label={ t(`navigator.${ item.title.toLowerCase() }`) }
            />
    }

    const BottomBar = () => {
        return <BottomBarWrapper
            value={ location.pathname }
            onChange={ (event, newValue) => changePage(newValue)(event) }
            showLabels>
            { routes.map(item => makeBottomBarItem(item)) }
        </BottomBarWrapper>
    }

    return <NavigatorWrapper hasBottomBar={ hasBottomBar }>
        <NavigatorContainer>
            <ContentWrapper ischat={ isChat ? 1 : 0 }>
                <Switch>
                    {
                        routes.map(route => {
                            return <Route key={ route.path } exact={ route.path === '/' } { ...route } />
                        })
                    }
                    <Redirect from='*' to='/404' />

                </Switch>
            </ContentWrapper>

            <Hidden mdUp>
                <BottomBar />
            </Hidden>

            <Hidden smDown>
                <SideBar />
            </Hidden>

        </NavigatorContainer>
    </NavigatorWrapper>
}

const sideBarWidth = 256

const ActionButton = styled(Button)`
    padding: 0 10px;
    margin-bottom: 10px;
`

const ButtonContainer = styled.div`
    width:  100%;
    padding: 0 20px;
    margin-top: auto;
    margin-bottom: 100px;
    ${ ({ hasbottombar }) => hasbottombar && 'display: none;' }
`
const LogoWrapper = styled(Box)`
    align-self: center;
    margin: 40px 0 96px 0;
    height: 56px;
`

const SideBarItem = styled(Box)`
    color: ${ ({ theme }) => theme.palette.secondary.main };
    font-size: 18px;
    margin-left: 16px;
    padding: 8px;
    width: 100%;

    ${ ({ active, theme }) => active && `
        background: ${ theme.palette.background.paper };
        color: ${ theme.palette.primary.main };  
        font-weight: 700;
        icon {
            color: ${ theme.palette.primary.main };
        }
    `};
    
    span {
        margin-left: 8px;
    }
    
    align-items: center;
    cursor: pointer;
    display: flex;
`

const ContentWrapper = styled.div`
    background: ${ ({ theme }) => theme.palette.background.paper };
    min-height: ${ ({ ischat }) => !ischat && 'calc(100vh - 40px)' };
    width: 100%; 
`

const NavigatorContainer = styled.div`
    align-items: flex-start;
    flex: 1;
    flex-direction: column;

    &&& {
        height: 100%;
    }
`

const NavigatorWrapper = styled.div`
    height: 100%;
    ${ props => props.hasBottomBar ? 'margin-left: 0px;' : `margin-left: ${ sideBarWidth }px;` };
    width: calc(100% - ${ props => props.hasBottomBar ? 0 : sideBarWidth }px);
    
    ${ ContentWrapper } {
        ${ props => props.hasBottomBar ? 'padding-bottom: 56px;' : 'padding: 20px 0 0 0;' } 
    }

    ${ NavigatorContainer } {
        margin-right: ${ props => props.hasBottomBar ? '0' : '40px' };
        padding-top: ${ props => props.hasBottomBar ? '0' : '40px' };
    }
`

const SideBarWrapper = styled.div`
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    height: 100%;
    left: 0;
    overflow-x: hidden;
    position: fixed;
    top: 0;
    width: ${ sideBarWidth }px;
    z-index: 1;
`

const BottomBarWrapper = styled(BottomNavigation)`
    bottom: 0;
    left: 0;
    overflow-x: hidden;
    position: fixed;
    right: 0;
    z-index: 1;
`

const StyledBottomNavigationAction = styled(BottomNavigationAction)`
    color: ${ ({ theme, active }) =>
        active ? theme.palette.primary.main : theme.palette.secondary.main }; 
    min-width: unset;
`

export default () => <NavigatorProvider>
    <Navigator />
</NavigatorProvider>
import { isEmpty } from '../../shared/'

const sortAlphabetically = calendars => {
    let list = [...calendars]

    return list.sort((first, second) => {
        const firstCal = first.name.toLowerCase()
        const secondCal = second.name.toLowerCase()

        return firstCal === secondCal ? 0 : (firstCal > secondCal ? 1 : -1)
    })
}

const getCalendarOrderKeyByPermissions = calPermissions => {
    let key = ''

    if (calPermissions.canEdit) {
        key = 'canEdit'
    } else if (calPermissions.canAdd) {
        key = 'canAdd'
    }

    return key
}

const sortCalendarsByPermissions = calendars => {
    let list = [...calendars]
    const sortOrder = {
        'canEdit': 1,
        'canAdd': 2,
        '': 3
    }

    return list.sort(calendar => {
        const key = getCalendarOrderKeyByPermissions(getCalendarPermissions(calendar))
        return sortOrder[key]
    })
}

export const getCalendarPermissions = calendar => {
    if (isEmpty(calendar)) {
        return {  // We use permissions as placeholder till data from the api arrives.
            canView: true,
            canAdd: false,
            canEdit: false,
            canShare: false
        }
    }


    return calendar.currentUser?.permissions ?? calendar.calendarLink?.permissions ?? {
        ...calendar.defaultPermissions, // default permissions can't be used if the calendar is not accepted
        canAdd: false,
        canEdit: false,
        canShare: false,
    }
}

export const getOrderedCalendarsWithAddRights = calendars => {
    if (!calendars.length) {
        return []
    }


    let calendarsWithAdd = calendars.filter(cal => cal.permissions.canAdd && !cal.isPersonal)
    const personalCalendar = calendars.filter(cal => cal.isPersonal)[0]
    calendarsWithAdd = sortAlphabetically(calendarsWithAdd)
    calendarsWithAdd = sortCalendarsByPermissions(calendarsWithAdd)
    calendarsWithAdd.unshift(personalCalendar)
    return calendarsWithAdd
}
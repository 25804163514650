import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import i18n from '../../../i18n'
import { Button, Container, Hidden, Typography } from '@material-ui/core'
import SearchFieldBar from './SearchFieldBar'

const TopBarBody = ({ size, inheritedProps }) => {
    const { name, createCallback, searchPlaceholder, searchCallback, hideActionButton, hideSearchBar, searchFieldRef } = inheritedProps
    const { t } = useTranslation('', i18n)

    return <TopBarWrapper screensize={size} data-test-id='top-bar'>
        <LeftSide screensize={size}>
            <TopBarLabel variant='h5' data-test-id='top-bar-label'>{name}</TopBarLabel>
            {
                !hideActionButton && <CreateButton
                    color='primary'
                    onClick={createCallback}
                    size='small'
                    variant='contained'
                    data-test-id='create-btn'
                >
                    {size === 'smDown' ? `+ ${t('button.new')}` : `+ ${t('button.create')}`}
                </CreateButton>

            }
        </LeftSide>
        {!hideSearchBar &&
            <SearchFieldBar
                size={size}
                searchFieldRef={searchFieldRef}
                searchPlaceholder={searchPlaceholder}
                searchCallback={searchCallback}
            />
        }
    </TopBarWrapper>
}

const TopBar = (props) => {
    return <>
        <Hidden mdUp>
            <TopBarBody
                size={'smDown'}
                inheritedProps={props}
            />
        </Hidden>
        <Hidden smDown>
            <TopBarBody
                size={'mdUp'}
                inheritedProps={props}
            />
        </Hidden>
    </>
}

const TopBarWrapper = styled(Container)`
    align-items: center;
    background-color: white;
    justify-content: space-between;
    left: auto;
    position: sticky;
    padding-bottom: 20px;
    padding-top: 20px;
    right: 0;
    top: 0;
    z-index: 10;
    ${ ({ screensize }) => {
        return screensize === 'smDown' ?
            { 'display': 'block' }
            : { 'display': 'flex' }
    }}
`
const LeftSide = styled.div`
    display: flex;
    ${ ({ screensize }) => screensize === 'smDown' && 'justify-content: space-between;'};
`
const TopBarLabel = styled(Typography)`
    font-weight: 700;
    margin-right: 12px;
`
const CreateButton = styled(Button)`
    font-weight: 700;
`
TopBarBody.propTypes = {
    size: PropTypes.string,
    inheritedProps: PropTypes.shape({
        name: PropTypes.string,
        createCallback: PropTypes.func,
        searchPlaceholder: PropTypes.string,
        searchCallback: PropTypes.func,
        hideActionButton: PropTypes.bool,
        hideSearchBar: PropTypes.bool
    })
}

TopBar.propTypes = {
    props: PropTypes.shape({
        name: PropTypes.string,
        createCallback: PropTypes.func,
        searchPlaceholder: PropTypes.string,
        searchCallback: PropTypes.func
    })
}

export default TopBar
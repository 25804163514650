import { call, put, takeLatest } from 'redux-saga/effects'
import { GET_EVENT_DETAILS, setEventDetails, getEventDetailsInfo } from '..'

function* fetchEventDetails(action) {

    const { data, ok, status } = yield call(getEventDetailsInfo, action.payload.eventId)

    if (ok) {
        if (data.lastActionType === 'delete') {
            yield put(setEventDetails({
                error: 404
            }))
        } else {
            yield put(setEventDetails(data))
        }

    } else {
        yield put(setEventDetails({
            error: status
        }))
    }

}

export default function* eventsFetchSaga() {
    yield takeLatest(GET_EVENT_DETAILS, fetchEventDetails)
}

import React from 'react'
import Grid from '@material-ui/core/Grid'
import { VDO_RGX } from '../utils/regexUtils'
import ReactPlayer from 'react-player'
import RegularButton from './RegularButton'
import { buildTwitterIframeSrc } from '../utils/urlBuilder'
import FileAttachment from './FileAttachment'
import Typography from '@material-ui/core/Typography'
import { useTheme } from '@material-ui/styles'
import styled from 'styled-components'
import PropTypes from 'prop-types'

//TO-DO establish proper contrast text
const advancedDescSwitch = (attachment, theme) => {
    switch (attachment.type) {

        case 'text': {
            return attachment.data.text.match(VDO_RGX) ? <ReactPlayer url={attachment.data.text} width='100%' controls={true} /> :
                <TextContainer color='textPrimary' gutterBottom={true}> {attachment.data.text} </TextContainer>
        }
        case 'video': {
            return <ReactPlayer url={attachment.data.url} width='100%' controls={true} />
        }
        case 'image': return <img width='100%' height='auto' alt='desc_image' src={attachment.data.url} />
        case 'button': {
            return <RegularButton
                bgColor={attachment.data.color}
                text={attachment.data.text}
                textColor={theme.palette.primary.contrastText}>
            </RegularButton>
        }
        case 'document': {
            return <FileAttachment attachment={attachment}></FileAttachment>
        }
        case 'twitter': return <TwitterIframe
            frameBorder={0}
            title='twitter'
            src={buildTwitterIframeSrc(attachment.data.widgetId)}>
        </TwitterIframe>
        default: return null
    }
}

const AdvancedDesc = ({ attachments }) => {

    const theme = useTheme()
    return (
        <Grid container direction='column'>
            <Grid item>
                {
                    attachments.map((attachment, index) => <Gutters key={index} >
                        {advancedDescSwitch(attachment, theme)}
                    </Gutters>)
                }
            </Grid>
        </Grid>
    )
}

AdvancedDesc.propTypes = {
    attachments: PropTypes.array
}

const TwitterIframe = styled.iframe`
    width : 100%;
    height : 500px
`
const TextContainer = styled(Typography)`
  ${ ({ theme }) => `
      width : 100%;
      font-size : ${ theme.spacing(2)}px;
      font-weight : 500;
      padding : ${theme.spacing(2)}px;
      background : ${theme.palette.background.default};
      white-space : pre-wrap
  ` };
`
const Gutters = styled.div`
    ${ ({ theme }) => `
    width : 100%;
    margin-bottom: ${theme.spacing(2)}px;
    ` };
`

export default AdvancedDesc
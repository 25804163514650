import { SET_LOADER, SET_MY_CALENDARS } from './calendars.actions'

const initialState = {
    items: [],
    isLoading: false
}

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_MY_CALENDARS: {
            return {
                ...state,
                items: action.payload
            }
        }
        case SET_LOADER: {
            return {
                ...state,
                isLoading: action.payload.isLoading
            }
        }
        default:
            return state
    }
}
import firebase from 'firebase/app'
import 'firebase/messaging'
import { subscribeToFirebaseApi } from '../account'

const instance = {
    isMessagesGranted: false,
    isInitialized : false,
    token: null
}

const initialize = () => {
    if (instance.isInitialized) {
        return console.debug('Firebase already initialized')
    }

    firebase.initializeApp(window.fireOptions)

    instance.isInitialized = true
    console.log('Firebase initialized')
}

const registerToken = async () => {
    const messaging = firebase.messaging()
    const token = await messaging.getToken()
    instance.token = token
    await subscribeToFirebaseApi(token)
}

const messagingInit = () => {
    if (!('Notification' in window)) {
        return console.error('Browser does not support push notifications!')
    }

    switch (Notification.permission) {
        case 'granted':
            instance.isMessagesGranted = true
            registerToken().finally()
            break
        case 'default':
            Notification.requestPermission().then(function (permission) {
                if (permission === 'granted') {
                    instance.isMessagesGranted = true
                    registerToken().finally()
                } else {
                    instance.isMessagesGranted = false
                }
            })
            break
        default:
            instance.isMessagesGranted = false
            console.log('Push notifications disabled.')
            break
    }
}

const logout = () => {
    if (!instance.isInitialized || instance.token === null) {
        return
    }

    firebase.messaging().deleteToken(instance.token).finally(() => {
        instance.isMessagesGranted = false
        instance.token = null
    })
}

export default {
    initialize,
    messagingInit,
    logout,

    isInitialized: () => instance.isInitialized
}
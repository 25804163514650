import {
    apiService, HttpError, getApiUrl,
    S3_GALLERY, dataURItoBlob
} from '../../shared'

const fetchMyCalendars = async () => await apiService.get('/calendar/myCalendars', { ignoreDeleted: true })

const fetchCalendar = async calendarId => {
    const { data, ok, status } = await apiService.get(`/calendars/${calendarId}`)

    if (!ok) {
        return {
            error: status
        }
    }

    return data
}

const fetchCalendarUserList = async (id, resultsPerPage, skip = 0) => {
    const { data, ok, status } =
        await apiService.get('/calendar/users?allPermissions', { calendarId: id, resultsPerPage, skipNumber: skip })
    //verifyOk(ok, status)

    return data
}

const getCalendarUser = async (id, user, resultsPerPage = 100) => {
    const { data, ok, status } =
        await apiService.get('/calendar/users?allPermissions', { calendarId: id, q: user, resultsPerPage })

    //verifyOk(ok, status)

    return data
}

const updateCalendarSettings = async (id, updatedSettings) => {
    const { data, ok, status } =
        await apiService.put(`/calendar/settings?calendarId=${id}`, updatedSettings)
    verifyOk(ok, status)
    return data
}

const createCalendar = async (form, croppedImg) => {
    let formData = form

    if (croppedImg) {
        const imgId = new Date().getTime()
        await uploadImage(croppedImg, imgId)
        formData = {
            ...form,
            coverPhoto: {
                croppedUrl: `${S3_GALLERY}/photos/live/${imgId}-size=medium`,
                url: `${S3_GALLERY}/photos/live/${imgId}-size=original`
            }
        }
    }

    const { data, ok, status } = await apiService.post('/calendar', formData)
    verifyOk(ok, status)
    return data
}

const prepareUploadToS3 = async () => {
    const { data, ok, status } = await apiService.put('/imageprocessor/authorise', {}, { baseURL: getApiUrl(), headers: { 'Content-Type': 'application/octet-stream' } })
    verifyOk(ok, status)
    return data
}

const uploadImage = async (img, imgId) => {
    const credentials = await prepareUploadToS3()

    const fileName = imgId + '-size=original'
    const fd = new FormData()
    const type = 'image/jpeg'
    const fKey = 'photos/live/'
    const file = dataURItoBlob(img)

    fd.append('key', fKey + fileName)
    fd.append('acl', 'public-read')
    fd.append('Content-Type', type)
    fd.append('file', file)
    fd.append('AWSAccessKeyId', credentials.key)
    fd.append('policy', credentials.policy)
    fd.append('signature', credentials.signature)

    const { data, ok, status } = await apiService.post(S3_GALLERY, fd, { headers: {}, baseURL: {} })
    verifyOk(ok, status)
    return data
}

const uploadCroppedImg = async (form, croppedImg) => {
    const imgId = new Date().getTime()
    await uploadImage(croppedImg, imgId)
    const formData = {
        ...form,
        coverPhoto: {
            croppedUrl: `${S3_GALLERY}/photos/live/${imgId}-size=medium`,
            url: `${S3_GALLERY}/photos/live/${imgId}-size=original`
        }
    }
    return formData
}

const editCalendar = async (form, croppedImg) => {
    let formData = form
    if (croppedImg) {
        formData = await uploadCroppedImg(form, croppedImg)
    }
    const { data, ok, status } = await apiService.put(`/calendar?calendarId=${formData.calendarId}`, formData)
    verifyOk(ok, status)
    return data
}

const verifyOk = (ok, status) => {
    if (!ok) {
        throw new HttpError(status)
    }
}
export {
    fetchMyCalendars,
    fetchCalendar,
    fetchCalendarUserList,
    getCalendarUser,
    updateCalendarSettings,
    uploadImage,
    createCalendar,
    editCalendar
}
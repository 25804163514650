import React, { createContext, useContext, useState } from 'react'
import { useHistory, useLocation } from 'react-router'
import PropTypes from 'prop-types'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import i18n from '../../../i18n'
import useDates from '../hooks/datesHook'
import useEvent from '../hooks/eventHook'
import { createEvent, editEvent } from '../'
import { isEmpty, isMultiDay, convertDateToUTC, NAME_REGEX } from '../../shared/'

const CreateEditEventContext = createContext({})

const CreateEditEventProvider = ({ children }) => {
    const { t } = useTranslation('', i18n)
    const localTimezone = moment.tz.guess()
    const history = useHistory()
    const location = useLocation()
    const eventFromState = location.state.event || {}
    const calendarIdFromState = location.state.calendarId || ''
    const isEditMode = !isEmpty(eventFromState)
    const { handleDateChange, adjustEndDate, getInitDates } = useDates(eventFromState)
    const [hasChanged, setHasChanged] = useState(false)
    const { event, setEvent } = useEvent(eventFromState, calendarIdFromState)

    const validate = values => {
        const errors = {}
        if (!values.name) {
            errors.name = t('event.errors.nameRequired')
        } else if (values.name.length < 3) {
            errors.name = t('event.errors.nameLength')
        } else if (!NAME_REGEX.test(values.name)) {
            errors.name = t('event.errors.nameInvalid')
        }

        if (!!values.description && values.description.replace(/\s/g, '') === '') {
            errors.description = t('event.errors.descriptionInvalid')
        }

        return errors
    }

    const handleAllDaySwitch = isAllDay => {
        setEvent({
            ...event,
            isAllDay,
            ...getInitDates(event.startDate, event.endDate),
            startTimezone: isAllDay ? localTimezone : event.startTimezone,
            endTimezone: isAllDay ? localTimezone : event.endTimezone
        })
    }

    const handleCalendarChange = calendar => {
        setEvent({
            ...event,
            originatingCalendar: calendar,
            originatingCalendarId: calendar.calendarId
        })
    }

    const handleEventDateChange = (limit, value) => {
        const datesUpdates = handleDateChange(limit, event, value)
        setEvent({
            ...event,
            ...datesUpdates
        })
    }

    const handleTimezoneSelect = (limit, value) => {
        setEvent({
            ...event,
            [`${ limit }Timezone`]: value,
            endDate: adjustEndDate(event, limit, value)
        })
    }

    // Will look differently when actually implementing advanced description
    const manageDescription = (formValues, attachments) => {
        let textItemFound = false
        let updatedAttachments = [...attachments]

        if (formValues.description) {
            updatedAttachments.map(att => {
                if (att.type === 'text' && !textItemFound) {
                    textItemFound = true
                    att.data.text = formValues.description
                }
                return att
            })

            if (!textItemFound) {
                return [...updatedAttachments, {
                    type: 'text',
                    data: {
                        text: formValues.description
                    }
                }]
            }

            return updatedAttachments
        }

        return updatedAttachments.filter(att => {
            if (att.type === 'text' && !textItemFound) {
                textItemFound = true
                return false
            }
            return att
        })
    }

    const handleEventSave = async (formValues) => {
        let eventToSave = {
            ...event,
            name: formValues.name,
            isMultiDay: isMultiDay(event),
            calendarId: event.originatingCalendarId,
            occurrenceDate: event.startDate.toISOString(),
            occurrenceId: `${ event.eventId }-${ moment(event.startDate).format('YYYY-MM-DD') }`,
        }

        if (event.isAllDay) {
            eventToSave.startDate = convertDateToUTC(moment(event.startDate).startOf('day'), 'GMT')
            eventToSave.endDate = convertDateToUTC(moment(event.endDate).endOf('day'), 'GMT')
        } else {
            eventToSave.startDate = convertDateToUTC(eventToSave.startDate, eventToSave.startTimezone)
            eventToSave.endDate = convertDateToUTC(eventToSave.endDate, eventToSave.endTimezone)
        }

        eventToSave.attachments = manageDescription(formValues, eventToSave.attachments)

        // For now we edit single instances of repeating events
        if (eventToSave.repeatingRule) {
            eventToSave.parentId = ''
        }

        if (isEditMode) {
            await editEvent(eventToSave)
        } else {
            await createEvent(eventToSave)
        }
        setHasChanged(false)
        history.goBack()
    }

    return (
        <CreateEditEventContext.Provider
            value={ {
                event, hasChanged, setHasChanged,
                handleAllDaySwitch, handleEventDateChange, handleTimezoneSelect,
                handleCalendarChange, handleEventSave, validate, isEditMode
            } }>
            { children }
        </CreateEditEventContext.Provider>
    )
}

CreateEditEventProvider.propTypes = {
    children: PropTypes.object
}

export const useCreateEditEvent = () => useContext(CreateEditEventContext)
export default CreateEditEventProvider
import { SET_EVENTS, REFRESH_EVENTS, SET_ANCHOR, INITIALIZE, REINITIALIZE } from '..'
import { CLEAR_EVENTS } from './events.actions'
import { isMultiDay } from '../../shared/utils/dateUtils'
const initialState = {
    items: []

}

export default (state = initialState, action) => {

    switch (action.type) {
        case SET_EVENTS: {
            let modifiedStateObj = {}

            if (action.payload && action.payload.items && action.payload.items.length > 0) {

                let filteredEvents = action.payload.items.filter(event => !isMultiDay(event))

                if (action.direction) {
                    let pageTokenOfCurrentFetch = action.payload.metadata.pageToken
                    if (action.direction === 'backward' && action.payload.items.length < 50) {
                        pageTokenOfCurrentFetch = null
                    }
                    modifiedStateObj[action.direction === 'backward' ? 'pageTokenPast' : 'pageToken'] = pageTokenOfCurrentFetch
                    modifiedStateObj.items = (action.direction === 'backward') ? [
                        ...filteredEvents.reverse(),
                        ...state.items,
                    ] : [
                            ...state.items,
                            ...filteredEvents]

                    return {
                        ...state,
                        ...modifiedStateObj
                    }

                } else {
                    return {
                        initialized: action.searchString && action.searchString !== '' ? state.initialized : undefined,
                        searchActive: true, // To Recalculate Height for Virtualization &  clear happens when searchString is empty
                        pageTokenPast: null, // To prevent dummy past loading skeletons
                        items: [
                            ...filteredEvents
                        ]
                    }
                }


            } else if (action.payload && action.payload.items && action.payload.items.length === 0) {

                if (action.direction) {
                    modifiedStateObj[action.direction === 'backward' ? 'pageTokenPast' : 'pageToken'] = null

                    return {
                        ...state,
                        ...modifiedStateObj
                    }

                } else {
                    return {
                        initialized: action.searchString && action.searchString !== '' ? state.initialized : undefined,
                        searchActive: true,
                        pageTokenPast: null,
                        items: []
                    }

                }

            } else {
                return state
            }
        }
        case REFRESH_EVENTS: {

            return action.position === null || action.position >= 0 ? {
                ...state,
                index: action.position
            }
                : state
        }
        case CLEAR_EVENTS: {
            return {
                items: state.items.length > 0 ? [] : state
            }
        }
        case SET_ANCHOR: {
            return action.anchor >= 0 ? {
                ...state,
                anchor: {
                    index: action.anchor,
                    position: action.position
                }
            }
                : state
        }
        case INITIALIZE: {
            return {
                ...state,
                initialized: action.initialize
            }
        }
        case REINITIALIZE: {
            return {
                ...state,
                initialized: undefined,
                pageTokenPast: null,
            }
        }
        default:
            return state
    }
}
import React, { Fragment } from 'react'
import styled from 'styled-components'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined'
import Attendance from '../../rsvps/components/Attendance'
import { useMediaQuery, useTheme, Typography, Hidden } from '@material-ui/core'
import { RsvpInfo } from '../../rsvps'
import moment from 'moment-timezone'
import PropTypes from 'prop-types'

const EventCard = ({ item, onClick, highlight }) => {

  let theme = useTheme()
  let colorForCard = (item && item.colorSettings.type === 'manual') ?
    item.colorSettings.cardColor :
    item && item.originatingCalendar && item.originatingCalendar.color

  if (colorForCard === undefined) {
    colorForCard = theme.palette.primary.highlight
  }

  return (
    <EventPaper
      onClick={onClick}
      elevation={0}
      bordercolor={colorForCard}>
      <Grid container direction='row' justify='center' alignItems='center' >
        <EventInfoCard container alignItems='flex-start' spacing={1} item xs={8} sm={9}>
          <Hidden only={['xs']}>
            <DateGrid item sm={5} md={4} xl={3} container>
              {item && <DateText noWrap color={highlight ? 'textSecondary' : 'initial'}> {`${moment(item.startDate).format('hh:mm A')}
              -  ${moment(item.endDate).format('hh:mm A')}`}</DateText>}
            </DateGrid>
          </Hidden>
          <Hidden only={['sm', 'md', 'lg', 'xl']}>
            <Grid item xs={4} container direction='column' alignItems='center' >

              {item && <Fragment><EventInfoText noWrap color={highlight ? 'textSecondary' : 'initial'}>
                {moment(item.startDate).format('hh:mm A')}
              </EventInfoText>
                <EventInfoText>
                  to
              </EventInfoText>
                <EventInfoText>
                  {moment(item.endDate).format('hh:mm A')}
                </EventInfoText></Fragment>}
            </Grid>
          </Hidden>

          <EventInfoCard item container xs spacing={1} direction='column' alignItems='flex-start' zeroMinWidth>
            <Grid zeroMinWidth item xs>
              <EventInfoText noWrap color={highlight ? 'textSecondary' : 'initial'}>{item && item.name}</EventInfoText>
            </Grid>
            <FlexedGridItem item zeroMinWidth xs >
              {item && item.location && (item.location.name || item.location.address) &&
                <LocationGrid container item alignItems='center'>
                  <LocationIcon></LocationIcon>
                  <LocationText noWrap color='textSecondary'>{item.location.name} {item.location.address}</LocationText>
                </LocationGrid>}
            </FlexedGridItem>

          </EventInfoCard>
        </EventInfoCard>

        <RsvpGrid container justify='space-evenly' direction='column' alignItems='center' item xs={4} sm={3}>
          <Grid item>
            <RsvpInfo eventId={item && item.eventId}></RsvpInfo>
          </Grid>
          <FlexedGridItem item>
            <Attendance rsvpsStatistics={item && item.rsvpsStatistics}></Attendance>
          </FlexedGridItem>

        </RsvpGrid>
      </Grid>
    </EventPaper >
  )
}

EventCard.propTypes = {
  item: PropTypes.object,
  onClick: PropTypes.func,
  highlight: PropTypes.string
}

const EventPaper = styled(Paper)`
    flex-grow : 1; 
    cursor : pointer;
    border-left : 8px solid;
    border-bottom-left-radius: 0;
    border-bottom-right-radius : 0;
    border-top-left-radius: 0;
    border-top-right-radius : 0;
   
    ${ ({ theme, matches = useMediaQuery(useTheme().breakpoints.up('sm')), bordercolor }) => `
            background: ${ theme.palette.background.default}; 
            margin : ${ matches ? theme.spacing(3) + 'px' : theme.spacing(3) + 'px ' + theme.spacing(0) + 'px'}; 
            padding : ${ matches ? theme.spacing(2) + 'px' : theme.spacing(2) + 'px ' + theme.spacing(0) + 'px'}; 
            border-left-color : ${bordercolor}
    ` };

`

const EventInfoCard = styled(Grid)`
  
    font-weight : 500;
    flex-wrap : nowrap   
`
const FlexedGridItem = styled(Grid)`
    display : flex
`

const RsvpGrid = styled(Grid)`
    min-height : ${ ({ theme }) => theme.spacing(10) + 'px'}
`
const EventInfoText = styled(Typography)`
    
    ${ ({ theme }) => `
            font-size : ${ theme.spacing(2) + 'px'};
            font-weight : 500;
    ` };
    
         
`
const LocationGrid = styled(Grid)`
  display : flex;
  flex-wrap : nowrap
`
const LocationIcon = styled(LocationOnOutlinedIcon)`
  
color : ${ ({ theme }) => theme.palette.secondary.main};
font-size : ${ ({ theme }) => theme.spacing(2) + 'px'}
`

const DateText = styled(Typography)`
${ ({ theme }) => `
    min-width : ${ theme.spacing(16) + 'px'};
    font-weight : 500
` };
`

const DateGrid = styled(Grid)`
 min-width : ${ ({ theme }) => theme.spacing(16) + 'px'}
`

const LocationText = styled(Typography)`
    
    ${ ({ theme }) => `
            font-size : ${ theme.spacing(2) + 'px'};
            color : ${ theme.palette.secondary.main}
    ` };
    
         
`

export default EventCard

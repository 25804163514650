/* eslint-disable max-len */
import React from 'react'
import constants from '../../utils/notification.constants'
import styled from 'styled-components'
import moment from 'moment'

const chosenStatus = (status, isEvent, isRsvpUpdate) => {
    const isPositive = isRsvpUpdate ? status === constants.rsvpStatus.attending : status === constants.status.accepted
    const labelPositive = isEvent ? 'Going' : 'Accepted'
    const labelNegative = isEvent ? 'Not Going' : 'Declined'
    const resolvedLabel = isPositive ? labelPositive : labelNegative

    return <ChosenAction success={ isPositive }>{ resolvedLabel }</ChosenAction>
}

const continuesWithEvent = (isChosen, status, isRsvpUpdate) => isChosen ? chosenStatus(status, true, isRsvpUpdate) : ':'
const continuesWithCalendar = (isChosen, status) => isChosen ? chosenStatus(status, false) : ':'

export const getTitleContinuation = notification => {
    const { notificationType, status, payload } = notification

    const chosen = status !== constants.status.pending

    switch (notificationType) {
        case constants.types.shareEvent:
            return <span>
                <span> invited you to the following event and you replied </span>
                { continuesWithEvent(chosen, status) }
            </span>
        case constants.types.shareEventAccepted:
        case constants.types.shareEventRejected: {
            return <span>
                <span> responded to the invitation with </span>
                { continuesWithEvent(chosen, status) }
            </span>
        }
        case constants.types.rsvpUpdate: {
            const { initiator, target } = payload
            const isSelf = initiator.userId === target.userId

            return <span>
                {
                    isSelf ? <span> responded to the invitation with </span>
                      : <span> set your rsvp to </span>
                }
                { continuesWithEvent(chosen, status, true) }
            </span>
        }
        case constants.types.addEventToCalendar: {
            return <span> created a new event:</span>
        }
        case constants.types.eventUpdate: {
            return <span> made changes to the following event:</span>
        }
        case constants.types.eventDelete: {
            return <span> canceled the following event:</span>
        }
        case constants.types.shareCalendar:
            return <span>
                <span> shared the following calendar { chosen ? 'and you ' : 'with you' }</span>
                { continuesWithCalendar(chosen, status) }
            </span>
        default:
            return ''
    }
}

export const canShowViewAction = notification => {
    const { notificationType, payload } = notification

    if (notificationType.endsWith('deleted'))
        return false

    if (notificationType.lastIndexOf('organization') >= 0) {
        return false
    }

    // TODO api dose not send permissions for the calendar.
    // if (notificationType.lastIndexOf('calendar') >= 0) {
    //     //const hasPermissions = payload && payload.permissions && payload.permissions.canView
    //     // || TODO check if calendar link exists
    //
    //     const isCalendarChosen = notificationType === constants.types.shareCalendarAccepted ||
    //       notificationType === constants.types.shiftRejected //TODO fix api, doesnt include status
    //
    //     const chosen = status ? status !== constants.status.pending : isCalendarChosen
    //     const accepted = status === constants.status.accepted
    //     const isPrivate = notification.calendar.visibility === 'private'
    //
    //     return (chosen && !isPrivate) || accepted
    // }

    const hasPermissions = payload && payload.permissions && payload.permissions.canView
    const isCalendarWithPermissions = notificationType.lastIndexOf('calendar') > 0 && notification && hasPermissions
    return !notificationType.endsWith('deleted') || isCalendarWithPermissions
}

export const getBodyContent = (notification, viewFn) => {
    const { notificationType, details, event, calendar } = notification

    const hasViewAction = canShowViewAction(notification)

    const onClickView = target => () => {
        return hasViewAction && viewFn(target, notification)
    }

    switch (notificationType) {
        case constants.types.shareEventAccepted:
        case constants.types.shareEventRejected:
        case constants.types.eventDelete:
        case constants.types.shareEvent:
        case constants.types.eventUpdate:
        case constants.types.rsvpUpdate: {
            return <ActionLink data-test-id='notification-action-view'
                               clickable={ hasViewAction } onClick={ onClickView('event') }>
                { event.name } @ { moment(notification.event.startDate).format('h:mmA ddd DD MMM') }
            </ActionLink>
        }
        case constants.types.addEventToCalendar: {
            return <span>
                <ActionLink data-test-id='notification-action-view'
                            clickable={ hasViewAction }
                            onClick={ onClickView('event') }>{ event.name } @ { moment(notification.event.startDate)
                  .format('h:mmA ddd DD MMM') }
                </ActionLink> in calendar <ActionLink data-test-id='notification-action-view'
                                                      clickable={ hasViewAction }
                                                      onClick={ onClickView('calendar') }>{ calendar.name }</ActionLink>
            </span>
        }
        case constants.types.shareCalendar: {
            return <ActionLink data-test-id='notification-action-view'
                               clickable={ hasViewAction } onClick={ onClickView('calendar') }>
                { calendar.name }
            </ActionLink>
        }
        case constants.types.shareCalendarRemove:
            return <span>
                Canceled your invitation to <ActionLink data-test-id='notification-action-view'
                                                        clickable={ hasViewAction }
                                                        onClick={ onClickView('calendar') }>{ calendar.name }</ActionLink>
            </span>
        case constants.types.shareCalendarRejected:
        case constants.types.shareCalendarAccepted: {
            const accepted = notificationType === constants.types.shareCalendarAccepted //TODO fix api, doesnt include status
            return <span>
                <span> { accepted ? 'Accepted' : 'Declined' } your invitation to </span>
                <ActionLink data-test-id='notification-action-view'
                            clickable={ hasViewAction }
                            onClick={ onClickView('calendar') }>{ notification.calendar.name }</ActionLink>
            </span>
        }
        case constants.types.calendarUpdate: {
            return <span>Made changes to the following calendar: <ActionLink data-test-id='notification-action-view'
                                                                             clickable={ hasViewAction }
                                                                             onClick={ onClickView('calendar') }>{ calendar.name }</ActionLink>
            </span>
        }
        case constants.types.calendarDelete: {
            return <span>Deleted the following calendar: <ActionLink data-test-id='notification-action-view'
                                                                     clickable={ hasViewAction }
                                                                     onClick={ onClickView('calendar') }>{ calendar.name }</ActionLink>
            </span>
        }
        default:
            return details
    }
}

const ChosenAction = styled.span`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: ${ ({ theme, success }) => success ? theme.palette.success.main : theme.palette.error.main };
  font-weight: bold;
`

const ActionLink = styled.span`
  cursor: ${ ({ clickable }) => clickable ? 'pointer' : 'default' };
  font-weight: 700;
`
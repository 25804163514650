import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import i18n from '../../../i18n'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import useMediaQuery from '@material-ui/core/useMediaQuery'

const ResponsiveDialog = ({ open, title, children, handleClose, headerComponent }) => {
    const { t } = useTranslation('', i18n)

    const fullScreen = useMediaQuery(theme => theme.breakpoints.down('sm'))

    return (
        <div>
            <Dialog
                fullScreen={ fullScreen }
                open={ open }
                onClose={ () => handleClose('cancel') }
                aria-labelledby='responsive-dialog-title'
                data-test-id='responsive-dialog-body'
            >
                <DialogTitle id='responsive-dialog-title'
                    data-test-id='responsive-dialog-title'>
                    { title }
                    { headerComponent }
                </DialogTitle>
                <DialogContent data-test-id='responsive-dialog-content'>
                    { children }
                </DialogContent>
                <DialogActions>
                    <CapitalizedButton
                        autoFocus
                        variant='outlined'
                        size='small'
                        onClick={ () => handleClose('cancel') }
                        color='primary'
                        data-test-id='responsive-dialog-cancel'>
                        { t('button.cancel') }
                    </CapitalizedButton>
                    <CapitalizedButton
                        variant='contained'
                        size='small'
                        onClick={ () => handleClose('save') }
                        color='primary'
                        data-test-id='responsive-dialog-save'>
                        { t('button.save') }
                    </CapitalizedButton>
                </DialogActions>
            </Dialog>
        </div>
    )
}

const CapitalizedButton = styled(Button)`
    text-transform: capitalize;
`

ResponsiveDialog.propTypes = {
    open: PropTypes.bool,
    title: PropTypes.string,
    children: PropTypes.shape(),
    handleClose: PropTypes.func,
    headerComponent: PropTypes.element
}

export default ResponsiveDialog
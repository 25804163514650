import React, { createContext, useContext, useState } from 'react'
import propType from 'prop-types'
import { useHistory, useLocation } from 'react-router'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import i18n from '../../../i18n'
import { createCalendar, editCalendar, useCalendar, fetchCalendars } from '..'
import { NAME_REGEX } from '../../shared'

const ChangesContext = createContext({})

const CreateEditProvider = ({ children }) => {
    const placeholderUrl = 'https://gallery.allcal.com/photos/live/placeholder_cover_image%402x.png'
    const { t } = useTranslation('', i18n)
    const history = useHistory()
    const location = useLocation()
    const isEditMode = location.pathname.includes('/calendar/edit')
    const dispatch = useDispatch()
    const { calendar } = useCalendar()
    const calendars = useSelector(state => state.calendars.items)
    const selectedCalendar = calendars && isEditMode && calendars.find(cal => cal.calendarId === calendar.calendarId)

    const [hasChanged, setHasChanged] = useState(false)
    const [croppedImg, setCroppedImg] = useState()

    const initialValsCreate = {
        name: '',
        visibility: 'private',
        details: '',
        calendarSettings: {
            enableEta: 'false',
            enableNotice: 'true',
            enableChatPushNotifications: 'true',
        },
        color: '#4256c1',
        defaultPermissions: {
            canView: true,
            canAdd: false,
            canEdit: false,
            canShare: true
        },
        lastActionType: 'create'
    }

    const initialValsEdit = selectedCalendar && {
        ...calendar,
        name: calendar.name,
        visibility: calendar.visibility,
        details: calendar.details,
        lastActionType: 'update'
    }

    const validateName = (values, errors) => {
        if (!values.name) {
            errors.name = t('calendar.create.errors.titleRequired')
        } else if (values.name.trim().length < 3) {
            errors.name = t('calendar.create.errors.titleInvalid')
        } else if (!NAME_REGEX.test(values.name)) {
            errors.name = t('calendar.create.errors.emptySpaces')
        }
    }
    const validateEditCal = values => {
        const errors = {}
        validateName(values, errors)
        if (values.visibility === 'public' && values.coverPhotoLink === placeholderUrl && !croppedImg) {
            errors.image = t('calendar.create.errors.imageRequired')
        }
        return errors
    }

    const validateCreateCal = values => {
        const errors = {}
        validateName(values, errors)
        if (values.visibility === 'public' && !croppedImg) {
            errors.image = t('calendar.create.errors.imageRequired')
        }
        return errors
    }

    const submitNewCalendar = async (values, actions) => {
        const form = { ...values, details: values.details.trim() }
        const { defaultPermissions } = form

        if (values.visibility === 'private') {
            defaultPermissions.canAdd = true
        }

        const newCalendar = await createCalendar(form, croppedImg)
        actions.setSubmitting(false)
        dispatch(fetchCalendars())
        history.push(`/calendars/${ newCalendar.calendarId }/events`)
    }

    const updateCalendar = async (values, actions) => {
        const form = { ...values, details: values.details.trim() }
        const updatedCalendar = await editCalendar(form, croppedImg)
        actions.setSubmitting(false)
        history.push(`/calendars/${ updatedCalendar.calendarId }/events`)
    }

    return (
        <ChangesContext.Provider value={ {
            hasChanged, setHasChanged,
            croppedImg, setCroppedImg,
            submitNewCalendar, validateCreateCal, validateEditCal,
            updateCalendar,
            isEditMode, initialValsCreate, initialValsEdit, selectedCalendar, calendar
        } }>
            { children }
        </ChangesContext.Provider>
    )
}

CreateEditProvider.propTypes = {
    children: propType.object
}

export const useCalendarManager = () => useContext(ChangesContext)

export default CreateEditProvider
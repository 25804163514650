/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import i18n from '../../../i18n'
import styled from 'styled-components'
import { AppBar, Container, Tab, Tabs } from '@material-ui/core'
import { SearchFieldBar } from '../../shared'
import { useCalendar } from '..'

const CalendarContent = () => {
    const history = useHistory()
    const { searchFieldRef, searchEventsList, searchUsers, calendarTabs,
        activeTab, setActiveTab, isMobile, setSearchString, setReInitEvents } = useCalendar()
    const { t } = useTranslation('', i18n)

    useEffect(() => {
        if (calendarTabs[activeTab].hasSearch && searchFieldRef.current.value) {
            searchFieldRef.current.value = ''
            setSearchString('')
            setReInitEvents(true)
        }

    }, [activeTab])

    const executeTabSearch = (label, event) => {
        switch (calendarTabs[activeTab].label) {
            case 'People':
                return searchUsers(event)
            case 'Events':
                return searchEventsList(event)
            default:
                return label
        }
    }

    const handleTabChange = (event, newActiveTab) => {
        const newPathTab = calendarTabs[newActiveTab].path
        setActiveTab(newActiveTab)
        history.location.pathname !== newPathTab && history.push(newPathTab)
    }

    return <Container data-test-id='calendar-content-wrapper'>
        <StyledAppBar elevation={ 0 } position='sticky'>
            <TabsWrapper ismobile={ isMobile ? 1 : 0 }>
                <StyledTabs
                    aria-label='calendar tabs'
                    value={ activeTab }
                    indicatorColor='primary'
                    onChange={ handleTabChange }
                    variant='fullWidth'
                    data-test-id='calendar-content-tabs'
                >
                    { calendarTabs.map((tab, idx) => <Tab
                        aria-controls={ tab['aria-controls'] }
                        key={ `tab-${ idx }` }
                        component='a'
                        label={ t(`calendar.tabs.${ tab.label.toLowerCase() }`) }
                        href={ tab.path }
                        data-test-id={ tab.id }
                        onClick={ event => event.preventDefault() }

                    />) }
                </StyledTabs>
                { calendarTabs[activeTab].hasSearch &&
                    <SearchFieldBar
                        searchFieldRef={ searchFieldRef }
                        size={ isMobile ? 'smDown' : 'mdUp' }
                        searchPlaceholder={ t(`placeholder.${ calendarTabs[activeTab].label.toLowerCase() }`) }
                        searchCallback={ event => {
                            event.persist()
                            executeTabSearch(calendarTabs[activeTab].label, event)
                        } }
                    />
                }
            </TabsWrapper>
            { calendarTabs[activeTab].hasActionButton && calendarTabs[activeTab].actionComponent }
        </StyledAppBar >

        {
            <TabPanel
                aria-labelledby={ `nav-tab-${ activeTab }` }
                key={ calendarTabs[activeTab].label }
                role='tabpanel'
                data-test-id={ `nav-tabpanel-${ activeTab }` }
            >
                { calendarTabs[activeTab].component }
            </TabPanel>
        }
    </Container >
}

const StyledAppBar = styled(AppBar)`
    background-color: #FFFFFF;
    color: ${({ theme }) => theme.palette.secondary.main };
    display: flex;
    padding-bottom: 20px;
    flex-direction: column;
    justify-content: space-between
`

const StyledTabs = styled(Tabs)`
    min-height: 40px;
`

const TabsWrapper = styled.div`
    display: flex;

    ${({ ismobile }) => {
        return ismobile ? {
            'flex-direction': 'column',
            [`${ StyledTabs }`]: {
                'display': 'flex',
                'justify-content': 'space-around',
                'width': '100%'
            }
        } : {
                'flex-direction': 'row',
                [`${ StyledTabs }`]: {
                    'display': 'block',
                    'margin-right': '34px',
                    'justify-content': 'space-around',
                    'width': 'calc(100% - 360px)'
                }
            }
    } }
`

const TabPanel = styled.div`
    height: 100%;
`

export default CalendarContent
import React from 'react'
import { useSelector } from 'react-redux'
import { useTheme } from '@material-ui/styles'
import RsvpStatus from '../../shared/components/RsvpStatus'
import Skeleton from 'react-loading-skeleton'
import PropTypes from 'prop-types'

const RsvpInfo = ({ eventId }) => {
    const theme = useTheme()
    const rsvpStatus = useSelector(state => state.rsvps[eventId]
        && (state.rsvps[eventId].status || (state.rsvps[eventId].myRsvp
            && state.rsvps[eventId].myRsvp.status)))
    return rsvpStatus ? <RsvpStatus status={rsvpStatus} size={theme.spacing(2)} ></RsvpStatus> : <Skeleton width={100}></Skeleton>
}

RsvpInfo.propTypes = {
    eventId: PropTypes.string
}

export default RsvpInfo
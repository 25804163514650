import { useState, useEffect } from 'react'
import useIntegrationCompat from './integrationCompatHook'

const useWindowSize = (elementSiblingHeight) => {
    const {  siblingOffset } = useIntegrationCompat()

    const [siblingHeight, setSiblingHeight] = useState(elementSiblingHeight)
    const [listMaxHeight, setListMaxHeight] = useState(window.innerHeight - siblingHeight - siblingOffset)

    useEffect(() => {
        setListMaxHeight(window.innerHeight - siblingHeight - siblingOffset)
        const listener = () => {
            setListMaxHeight(window.innerHeight - siblingHeight - siblingOffset)
        }

        window.addEventListener('resize', listener)

        return () => {
            window.removeEventListener('resize', listener)
        }
    }, [siblingHeight, siblingOffset])

    return { listMaxHeight, setSiblingHeight }
}

export default useWindowSize
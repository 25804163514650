import React, { useEffect, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import i18n from '../../../i18n'
import styled from 'styled-components'
import { List, useMediaQuery } from '@material-ui/core'
import { ListInfiniteScroll, Loader, UserCard } from '../../shared'
import { useCalendar } from '..'

const CalendarPeople = () => {
    const { calendarPeopleList, fetchMoreUsers, fetchUsers } = useCalendar()
    const { t } = useTranslation('', i18n)

    const fetchUsersCallback = useCallback(fetchUsers, [])

    useEffect(() => {
        fetchUsersCallback()
    }, [ fetchUsersCallback ])

    const hasMoreUsers = calendarPeopleList.hasMoreUsers
    const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'))

    return (
        <StyledList ismobile={ isMobile ? 1 : 0 } data-test-id='calendar-people-list' >
            <ListInfiniteScroll
                dataLength={ calendarPeopleList.userList.length }
                endDelimiterHeight={ 32 }
                hasMore={ hasMoreUsers }
                next={ () => fetchMoreUsers() }
            >
                { calendarPeopleList.userList ?
                    (calendarPeopleList.totalNumberOfUsers === 0 ? <NotFound>{ t('emptyMessages.peopleList') }</NotFound> :
                        (calendarPeopleList.userList.map((user, i) => {
                            return <UserCard user={ user } key={ i } />
                        }))
                    ) : <Loader />
                }
            </ListInfiniteScroll>
        </StyledList>
    )
}

const NotFound = styled.div`
    background-color: ${ ({ theme }) => theme.palette.background.default };
    border-radius: 4px;
    height: 100px;
    margin-bottom: 20px;
    text-align: center;
    padding: 40px;
`

const StyledList = styled(List)`
    margin-top: ${({ ismobile }) => ismobile ? '0' : '12px' };
    height: 100%;
`

export default CalendarPeople
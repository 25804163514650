import React from 'react'
import { useTranslation } from 'react-i18next'
import i18n from '../../../i18n'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Loader, SettingCard } from '../../shared'
import { CalendarSchema, updateCalendar } from '../'
import { useCalendar } from '../context/CalendarProvider'

const CalendarSettings = () => {
    const { calendar, dispatch, setCalendar } = useCalendar()
    const { t } = useTranslation('', i18n)

    const calendarSettings = calendar.calendarSettings

    const handleChange = name => event => {
        const newSettings = { ...calendarSettings, [name]: event.target.checked }
        setCalendar({ ...calendar, calendarSettings: newSettings })
        dispatch(updateCalendar(calendar.calendarId, newSettings))
    }

    const hasChatThread = () => calendar.linkedEntities && calendar.linkedEntities.chatId

    return (
        <StyledContainer data-test-id='setting-card-list'>
            { calendarSettings ?
                (<>
                    <SettingCard
                        checked={ calendarSettings.enableNotices }
                        onChange={ handleChange('enableNotices') }
                        title={ t('calendar.settings.notices.title') }
                        description={ t('calendar.settings.notices.text') }
                        name={ 'enableNotices' }
                    />

                    { hasChatThread() &&
                        <SettingCard checked={ calendarSettings.enableChatPushNotifications }
                            onChange={ handleChange('enableChatPushNotifications') }
                            title={ t('calendar.settings.chat.title') }
                            description={ t('calendar.settings.chat.text') }
                            name={ 'enableChatNotif' }
                        /> }

                    <SettingCard checked={ calendarSettings.enableETA } onChange={ handleChange('enableETA') }
                        title={ t('calendar.settings.eta.title') }
                        description={ t('calendar.settings.eta.text') }
                        name={ 'enableETA' }
                    />
                </>)
                : <Loader /> }
        </StyledContainer>
    )
}

const StyledContainer = styled.div`
    margin-top: 20px;
`

CalendarSettings.propTypes = {
    calendar: PropTypes.shape(CalendarSchema)
}

export default CalendarSettings

import React, { useRef } from 'react'
import styled from 'styled-components'
import { EventsList } from '..'
import { useHistory } from 'react-router'
import i18n from '../../../i18n'
import { useTranslation } from 'react-i18next'
import Grid from '@material-ui/core/Grid'
import FullWidthTabs from '../../shared/components/FullWidthTabs'
import { TopBar } from '../../shared'
import { useDispatch } from 'react-redux'
import { queryEvents, reInitializeEvents } from '..'
import debounce from '../../shared/hooks/debounceHook'


const EventsListScreen = () => {
  const history = useHistory()
  const { t } = useTranslation('', i18n)
  const goToCreateEvent = () => history.push('/event/create', { calendarId: '' })
  const dispatch = useDispatch()
  const eventsListSearchRef = useRef()

  const searchEvents = debounce(ev => {
    const searchString = ev.target.value
    searchString ? dispatch(queryEvents(searchString)) : dispatch(reInitializeEvents())
  }, 500)

  return <EventsContent>
    <TopBar
      name={t('screenTitle.events')}
      searchPlaceholder={t('placeholder.events')}
      searchFieldRef={eventsListSearchRef}
      createCallback={goToCreateEvent}
      searchCallback={event => {
        event.persist()
        searchEvents(event)
      }}
    />
    <Container container justify='center'
    >
      <Grid item xs={12} md={11} lg={9}>
        <FullWidthTabs tabItems={[<EventsList key={t('event.filters.all')} eventsListSearchRef={eventsListSearchRef} />]} labels={[t('event.filters.all')]} />
      </Grid>
    </Container>
  </EventsContent>

}

const EventsContent = styled.div`

${ ({ theme }) => `
            background: ${ theme.palette.background.paper}; 
  ` };
 
`
const Container = styled(Grid)`
  width : 100%;
  margin : auto;
  ${ ({ theme }) => `
            padding: 0px ${ theme.spacing(2)}px; 
            marginTop : 0px;
  ` };
`


export default EventsListScreen
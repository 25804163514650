import React, { useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Route, Redirect, useLocation, useHistory } from 'react-router'
import { useAccount, AuthScreen } from '../account'
import { useChatAppContext } from 'chat-app/src/managers/AppManager'

const AuthenticationLayer = ({ children }) => {
    const location = useLocation()
    const history = useHistory()
    const { account, autoLoginUser } = useAccount()
    const { setChatUserFromStore } = useChatAppContext()

    const setChatUserFromStoreCb = useCallback(setChatUserFromStore, [])

    const isLogin = location.pathname === '/login'
    const isRegister = location.pathname === '/register'
    const isAutoLogin = location.pathname.indexOf('auto-login') > -1

    useEffect(() => {
        if (account !== null) {
            setChatUserFromStoreCb()
        }
    }, [ account, setChatUserFromStoreCb ])

    if (isAutoLogin) {
        autoLoginUser()
        return <></>
    }

    if (isLogin) {
        return account ? <Redirect to='' /> : <Route exact path='/login' component={ AuthScreen } />
    }

    if (isRegister) {
        return account ? <Redirect to=''/> : <Route exact path='/register' component={AuthScreen}/>
    }

    if (!account) {
        history.push('/register')
        return <></>
    }

    return <>{children}</>
}

AuthenticationLayer.propTypes = {
    children: PropTypes.object
}

export default AuthenticationLayer
import { SET_RSVPS, SET_RSVP_EVENT, SET_MY_RSVP } from '..'
const initialState = {

}

export default (state = initialState, action) => {

    switch (action.type) {
        //TO-DO This will no longer be needed soon 
        case SET_RSVPS: {

            let result = action.payload.reduce((h, obj) =>
                Object.assign(h, {
                    [obj.eventId]: { ...state[obj.eventId], ...obj }
                })
                , {})
            return Object.keys(result) && Object.keys(result).length > 0 ? {
                ...result
            } : state
        }
        case SET_RSVP_EVENT: {

            if (state[action.payload.eventId] && action.payload.skipNumber > 0) {

                let modifiedStateObj = Object.assign({}, state[action.payload.eventId])

                modifiedStateObj.users = state[action.payload.eventId].users ?
                    [...state[action.payload.eventId].users, ...action.payload.data.items]
                    : [...action.payload.data.items]

                if (action.payload.data.rsvp
                    && (action.payload.data.rsvp.status !== (state[action.payload.eventId].myRsvp
                        && state[action.payload.eventId].myRsvp.status)))
                    modifiedStateObj.myRsvp = { ...action.payload.data.rsvp }

                modifiedStateObj.hasMore = !!action.payload.data.items.length

                return {
                    ...state,
                    [action.payload.eventId]: modifiedStateObj
                }
            } else {
                let newStateObj = {}
                newStateObj.users = action.payload.data && action.payload.data.items ? [...action.payload.data.items] : []
                newStateObj.myRsvp = { ...action.payload.data.rsvp }
                newStateObj.hasMore = !!action.payload.data.items

                return {
                    ...state,
                    [action.payload.eventId]: newStateObj
                }
            }

        }
        case SET_MY_RSVP: {
            if (state[action.payload.event] && state[action.payload.event].myRsvp) {

                let modifiedStateObj = Object.assign({}, state[action.payload.event])
                modifiedStateObj.myRsvp = { ...action.payload }

                return {
                    ...state,
                    [action.payload.event]:
                        { myRsvp: modifiedStateObj }
                }
            } else {
                return state
            }
        }

        default:
            return state
    }
}
import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Grid, TextField } from '@material-ui/core'

const CustomTextField = ({ label, defaultValue, placeholder }) => {

    return (
        <CustomTextFieldContaier container>
            <FieldTitle>{ label }</FieldTitle>
            <StyledTextField
                placeholder={ placeholder }
                data-test-id={ `profile-${ label }` }
                InputProps={ {
                    readOnly: true,
                    disableUnderline: true
                } }
                defaultValue={ defaultValue }
            />
        </CustomTextFieldContaier>
    )
}

const FieldTitle = styled.div`
    color: ${ ({ theme }) => theme.palette.secondary.main };
    min-width: 64px;
    padding: 0px 6px;
`

const StyledTextField = styled(TextField)`
    .MuiInputBase-input {
        padding: 0px;
    },
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
`

const CustomTextFieldContaier = styled(Grid)`
    border-radius: 4px; 
    padding: 6px;
    margin: 6px 6px 0 0;
    flex-wrap: nowrap;
    background: ${ ({ theme }) => theme.palette.background.default };
`

CustomTextField.propTypes = {
    label: PropTypes.string,
    defaultValue: PropTypes.string,
    placeholder: PropTypes.string
}

export default CustomTextField
export const FETCH_MY_CALENDARS = '_$_GET_CALS'
export const SET_MY_CALENDARS = '_$_SET_CALS'
export const FETCH_CALENDAR_BY_ID = '_$_GET_CAL'
export const SET_LOADER = '_$_SET_LOADER'
export const UPDATE_CALENDAR = '_$_UPDATE_CALENDAR'

const fetchCalendars = (silent) => ({
    type: FETCH_MY_CALENDARS,
    payload: { silent: silent }
})

const setCalendars = (calendars) => ({
    type: SET_MY_CALENDARS,
    payload: [...calendars]
})

const fetchCalendarById = (id) => ({
    type: FETCH_CALENDAR_BY_ID,
    payload: id
})

const setCalendarsLoader = value => ({
    type: SET_LOADER,
    payload: { isLoading: value }
})

const updateCalendar = (id, newCalendar) => ({
    type: UPDATE_CALENDAR,
    payload: { newCalendar, id }
})

export {
    fetchCalendars,
    setCalendars,
    fetchCalendarById,
    setCalendarsLoader,
    updateCalendar
}

const types = {
    rsvpUpdate: 'rsvp update',
    rsvpAttendanceUpdate: 'attendance update',
    eventUpdate: 'event changed',
    eventDelete: 'event deleted',
    addEventToCalendar: 'event added to calendar',
    shareEvent: 'share event',
    shareEventAccepted: 'share event accepted',
    shareEventRejected: 'share event rejected',
    shareInfoEvent: 'share info event',
    shareEventRemove: 'remove event share',
    calendarUpdate: 'calendar changed',
    calendarDelete: 'calendar deleted',
    shareCalendar: 'share calendar',
    shareCalendarAccepted: 'share calendar accepted',
    shareCalendarRejected: 'share calendar rejected',
    shareCalendarOwnerRejected: 'change calendar ownership rejected',
    shareCalendarRemove: 'remove calendar share',
    shareCalendarOwner: 'change calendar ownership',
    shareCalendarOwnerRemove: 'cancel change calendar ownership',
    organizationAdd: 'organization add',
    organizationAddCalendar: 'organization add calendar',
    organizationAssignCalendar: 'organization assign calendar',
    shareCalendarNotifyOwner: 'notify owner for share calendar',
    shiftCreate: 'shift',
    shiftsCreate: 'shifts',
    shiftAccepted: 'shift accepted',
    shiftRejected: 'shift rejected',
    ticket: 'ticket',
    tickets: 'tickets',
    defaults: ''
}

const status = {
    pending: 'pending',
    accepted: 'accepted',
    rejected: 'rejected'
}

const rsvpStatus = {
    pending: 'pending',
    attending: 'attending',
    notAttending: 'not attending'
}

const removeNotificationTypes = [types.shareCalendarRemove, types.shareEventRemove]
const shareNotificationTypes = [types.shareCalendar, types.shareCalendarOwner, types.shareEvent]

export default {
    types,
    status,
    rsvpStatus,
    removeNotificationTypes,
    shareNotificationTypes
}
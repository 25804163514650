import { apiService } from '../../shared'

const getEventRsvpData = async (eventId, skipNumber) => {
    return await apiService.get(`/events/${eventId}/rsvps`, { resultsPerPage: 10, skipNumber: skipNumber, sortKey: 'name' })
}

const getRsvps = async () => {
    return await apiService.get('/rsvp')
}

const postMyRsvp = async (status, attendanceStatus, rsvpId) => {
    return await apiService.put('/rsvp', [{ status: status, attendanceStatus: attendanceStatus, rsvpId: rsvpId }])
}

export {
    getEventRsvpData,
    getRsvps,
    postMyRsvp
}   
import React from 'react'
import styled from 'styled-components'
import Typography from '@material-ui/core/Typography'
import LocationOnIcon from '@material-ui/icons/LocationOn'
import EventItemWrapper from './EventItemWrapper'
import PropTypes from 'prop-types'

const EventLocation = ({ location, onClick, isActive }) => {

    return (
        <EventItemWrapper icon={<LocationOnIcon></LocationOnIcon>} isActive={isActive} onClick={onClick}>
            {location && (location.address || location.name) ? <EventLocationText>
                {location.name && <TextContainer
                    color='textPrimary'
                    gutterBottom={true}> {location.name}
                </TextContainer>}
                {location.address &&
                    <TextContainer
                        color='textPrimary'
                        gutterBottom={true}> {location.address}
                    </TextContainer>}
            </EventLocationText> : <TextContainer
                color='textPrimary'
                gutterBottom={true}> No Location provided
                </TextContainer>}
        </EventItemWrapper>
    )
}

EventLocation.propTypes = {
    location: PropTypes.object,
    isActive: PropTypes.bool,
    onClick: PropTypes.func
}

const TextContainer = styled(Typography)`
  ${ ({ theme }) => `
      font-size : ${ theme.spacing(2)}px;
      font-weight : 500;
  ` };
`
const EventLocationText = styled.div`
    display : flex;
    flex-direction : column
`
export default EventLocation

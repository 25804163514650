import { apiService, getFirebaseUrl, HttpError } from '../../shared'

export const createAccount = async values => {
    const { ok, status } = await apiService.post('/auth/register', { ...values })

    verifyOk(ok, status)

    const response = await authEmailPassword(values.email, values.password)
    updateAccount(response)
    return response
}

export const authEmailPassword = async (email, password) => {
    let { data, ok, status } = await apiService.post('/auth/login', {
        email,
        password
    })

    verifyOk(ok, status)

    updateAccount(data)
    return data
}

export const authLoginToken = async (loginToken) => {
    apiService.setHeader('login-token', loginToken)
    let { data, ok, status } = await apiService.get('/account', {})

    verifyOk(ok, status)

    const account = { ...data, loginToken }
    updateAccount(account)
    return account
}

export const logout = async () => {
    await apiService.get('auth/logout')
}

export const subscribeToFirebaseApi = async (deviceToken) => {
    const subscribeResponse = await apiService.post(getFirebaseUrl(), { deviceToken }, { headers: { service: 'Allcal' } })
    if (subscribeResponse.error) {
        console.error('Subscribing to firebase api failed!', subscribeResponse.error)
    }
}

export const updateAccount = (userAccount) => {
    if (userAccount) {
        apiService.setHeader('login-token', userAccount.loginToken)
    } else {
        apiService.deleteHeader('login-token')
    }
}

export const getRecentTimezones = async (userId) => {
    const { data, ok, status } = await apiService.get(`accounts/${ userId }/timezones`)

    verifyOk(ok, status)
    return data
}

const verifyOk = (ok, status) => {
    if (!ok) {
        throw new HttpError(status)
    }
}

import React, { useRef, useEffect, Fragment, useCallback, useState } from 'react'
import styled from 'styled-components'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { buildEventUrl, EventCard } from '..'
import { getAllRsvps } from '../../rsvps'
import { isToday, groupEventByDate, getPastFetchAnchorItem, isSameYear } from '../../shared/utils/dateUtils'
import Skeleton from 'react-loading-skeleton'
import { Typography } from '@material-ui/core'
import { Link } from 'react-router-dom'
import { VariableSizeList as List } from 'react-window'
import InfiniteLoader from 'react-window-infinite-loader'
import AutoSizer from 'react-virtualized-auto-sizer'
import { getEventsData } from '../service/events.datasource'
import { setEvents, refreshEvents, setAnchor, clearEvents, initialize, reInitializeEvents } from '..'
import { usePrevious } from '../../shared'
import { getRowHeightForKey, getRowHeightIncludingDummyRows } from '../'
import PropTypes from 'prop-types'

const EventsList = ({ calendarId, eventsListSearchRef }) => {

    const dispatch = useDispatch()
    const history = useHistory()
    const location = history.location.pathname
    const events = useSelector(state => state.events.items)
    const resetAfterIndexVal = useSelector(state => state.events.index)
    const anchorItem = useSelector(state => state.events.anchor)
    const pageToken = useSelector(state => state.events.pageToken)
    const prevPageToken = usePrevious(pageToken)
    const pageTokenPast = useSelector(state => state.events.pageTokenPast)
    const groupedInfo = groupEventByDate(events)
    const groupedEvents = groupedInfo.items
    const initialAnchorIndex = groupedInfo.meta.initialAnchorIndex
    const initializedLoc = useSelector(state => state.events.initialized)
    const searchActive = useSelector(state => state.events.searchActive)
    const listRef = useRef(null)
    const [listRendered, setListRendered] = useState(false)
    const [noEvents, setNoEvents] = useState(false)

    const setAnchorItemIndex = useCallback((index, position) => {
        dispatch(setAnchor(index, position))
    }, [dispatch])

    const checkForRowHeightReCalc = useCallback((items, direction) => {

        let dateKeyToCheckFromNewData = (direction === 'backward') ? { ...items[items.length - 1] } : { ...items[0] }
        let dateKeyToCheckFromExistingData = (direction === 'backward') ?
            Object.keys(groupedEvents)[0] :
            Object.keys(groupedEvents)[Object.keys(groupedEvents).length - 1]
        let dispatchIndex = (direction === 'backward') ? 0 : Object.keys(groupedEvents).length - 1

        if (direction === 'backward' ||
            dateKeyToCheckFromExistingData === Object.keys(groupEventByDate([dateKeyToCheckFromNewData]).items)[0]) {
            dispatch(refreshEvents(dispatchIndex))
        }

    }, [dispatch, groupedEvents])

    const initialFetch = useCallback(async () => {
        events.length > 0 && dispatch(clearEvents())
        let reverseResp = await getEventsData({ direction: 'backward', calendarId: calendarId })
        dispatch(setEvents(reverseResp.data, 'backward'))

        let forwardResp = await getEventsData({ direction: 'forward', calendarId: calendarId })
        if (forwardResp.data && forwardResp.data.items && forwardResp.data.items.length > 0) {
            checkForRowHeightReCalc(forwardResp.data.items, 'forward')
        } else if (reverseResp.data && reverseResp.data.items && reverseResp.data.items.length > 0) {
            setAnchorItemIndex(reverseResp.data.items.length - 1, 'start')
            checkForRowHeightReCalc(forwardResp.data.items, 'backward')
        }
        dispatch(setEvents(forwardResp.data, 'forward'))
        return forwardResp.data && forwardResp.data.items && reverseResp.data && reverseResp.data.items ?
            reverseResp.data.items.concat(forwardResp.data.items) : []

    }, [calendarId, checkForRowHeightReCalc, dispatch, events, setAnchorItemIndex])


    useEffect(() => {
        if (pageToken && prevPageToken === undefined) {
            dispatch(getAllRsvps())
        }

        if (!initializedLoc || (initializedLoc !== location)) {
            initialFetch().then((data) => {
                if (data.length === 0) {
                    setNoEvents(true)
                }
            })
            dispatch(initialize(location))
        }
        if (searchActive) {
            if (eventsListSearchRef && !eventsListSearchRef.current) {
                dispatch(reInitializeEvents())
            }
            checkForRowHeightReCalc(events, 'backward')
        }

    }, [dispatch, pageToken, initializedLoc, initialFetch,
        location, prevPageToken, searchActive, checkForRowHeightReCalc, events, eventsListSearchRef])


    useEffect(() => {
        if (Object.keys(groupedEvents).length > 0 && listRendered) {

            if (resetAfterIndexVal >= 0) {
                listRef && listRef.current && listRef.current.resetAfterIndex(resetAfterIndexVal, true)
            }

            !anchorItem && setAnchorItemIndex(initialAnchorIndex, 'start')
            anchorItem && listRef.current.scrollToItem(anchorItem.index, anchorItem.position)
        }

    }, [groupedEvents, anchorItem, initialAnchorIndex, resetAfterIndexVal, setAnchorItemIndex, listRendered])


    const isRowLoaded = (index) => {
        return !!groupedEvents[Object.keys(groupedEvents)[index]]
    }

    const fetchPastEvents = (scrollDirection) => {

        (pageTokenPast) && getEventsData({
            pageToken: pageTokenPast,
            direction: 'backward', calendarId: calendarId
        }).then((resp) => {
            if (resp.data && resp.data.items.length > 0) {

                scrollDirection === 'backward' &&
                    setAnchorItemIndex(getPastFetchAnchorItem(resp.data.items.concat(events),
                        Object.keys(groupedEvents)[0]).indexToAnchor, 'center')
                checkForRowHeightReCalc(resp.data.items, 'backward')

            }
            if (resp.data && resp.data.items) {

                dispatch(setEvents(resp.data, 'backward'))

            }

        })

    }

    const onScroll = ({ scrollDirection, scrollOffset, scrollUpdateWasRequested }) => {

        if (scrollDirection === 'backward' && scrollOffset === 0) {
            if (!scrollUpdateWasRequested) {
                fetchPastEvents(scrollDirection)

            }

        }
    }

    const fetchFutureEvents = () => {

        (pageToken) && getEventsData({
            pageToken: pageToken,
            direction: 'forward', calendarId: calendarId
        }).then((resp) => {

            if (resp.data && resp.data.items.length > 0) {
                checkForRowHeightReCalc(resp.data.items, 'forward')
            }

            if (resp.data && resp.data.items) {
                dispatch(setEvents(resp.data, 'forward'))
                return new Promise(resolve => {
                    resolve()
                })
            }

        })


    }

    const loadMoreRows = () => {
        fetchFutureEvents()
    }

    const getRowHeight = (index) => {

        return index === 0 && pageTokenPast !== null ?
            getRowHeightIncludingDummyRows(groupedEvents, index) : getRowHeightForKey(groupedEvents, index)
    }

    const Row = ({ index, style }) => {
        const day = Object.keys(groupedEvents)[index]
        const skeleton = [...Array(2).keys()].map((key) => <Fragment key={key}><DateDummy><Skeleton></Skeleton></DateDummy>
            <EventCard>
                <Skeleton></Skeleton>
            </EventCard></Fragment>)

        return day ?
            <div key={day} style={style} onClick={() => { setAnchorItemIndex(index, 'smart') }}>
                {index === 0 && pageTokenPast !== null && skeleton}
                {(index !== 0 || (index === 0 && pageTokenPast === null)) && day && day.split('|')[0] &&
                    <DateHeader highlight={isToday(day)}>{day.split('|')[0]}
                        <Day highlight={isToday(day)}>{day.split('|')[1]}
                            {!isSameYear(day.split('|')[2]) && day.split('|')[2]}
                        </Day>
                    </DateHeader>}

                {groupedEvents && groupedEvents[day].map((item, index) =>

                    <StyledLink
                        key={index}
                        to={{
                            pathname: buildEventUrl(item.name, item.eventId, calendarId),
                            state: { from: location }
                        }}>
                        <EventCard key={index} item={item}>
                        </EventCard>
                    </StyledLink>)
                }
            </div>
            : skeleton
    }

    Row.propTypes = {
        index: PropTypes.number,
        style: PropTypes.object
    }


    return (noEvents || (searchActive && events.length === 0)) ? <div>No events to display</div> : <EventsListDiv>
        <AutoSizer>
            {({ height, width }) => (
                <InfiniteLoader
                    isItemLoaded={isRowLoaded}
                    loadMoreItems={loadMoreRows}
                    itemCount={Object.keys(groupedEvents).length}>
                    {({ onItemsRendered, ref }) => (
                        <List
                            ref={list => {
                                ref(list)
                                listRef.current = list
                                if (list && !listRendered)
                                    setListRendered(true)
                            }}
                            height={height}
                            onScroll={onScroll}
                            onItemsRendered={onItemsRendered}
                            itemCount={Object.keys(groupedEvents).length}
                            itemSize={getRowHeight}
                            overscanCount={2}
                            width={width}
                        >
                            {Row}
                        </List>

                    )}
                </InfiniteLoader>
            )}
        </AutoSizer>

    </EventsListDiv>
}

EventsList.propTypes = {
    calendarId: PropTypes.string
}


const StyledLink = styled(Link)`
    text-decoration : none;
`
const EventsListDiv = styled.div`
    width : 100%;
    height : 70vh;
    overflow-y : auto;
`
const DateDummy = styled(Typography)`
    width : 15%;
    padding-left : ${({ theme }) => theme.spacing(1)}px;
`
const DateHeader = styled.div`
    display : flex;
    width : 100%;
    ${({ theme, highlight }) => `
    font-size : ${theme.spacing(2)}px  ;
    padding-left: ${theme.spacing(1)}px  ;
    color : ${highlight ? theme.palette.text.secondary : theme.palette.secondary.contrastText};
    font-weight : ${highlight ? 600 : 500};
    ` };
`

const Day = styled.div`
    ${({ theme, highlight }) => `
        padding-left : ${theme.spacing(2)}px  ;
        color : ${highlight ? theme.palette.primary.highlight : theme.palette.secondary.main};
        font-weight : 500
    ` };
`

export default EventsList

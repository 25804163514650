import React from 'react'

import ActivityProvider, { useActivity } from './ActivityProvider'
import styled from 'styled-components'
import { useTheme } from '@material-ui/core'
import { TopBar } from '../../shared'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'

import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import ActivityList from '../components/ActivityList'
import AppBar from '@material-ui/core/AppBar'
import Container from '@material-ui/core/Container'

const ActivityScreen = () => {
    const theme = useTheme()
    const { tab, setTab, markAllRead } = useActivity()

    return <ActivityContext
        data-test-id='activity-content'
        container
        direction='column'
        justify='center'
        alignItems='center'>

        <TopBar data-test-id='activity-title' name='Activity' hideActionButton hideSearchBar/>
        <Separator color={ theme.palette.background.default }/>
        <TabBar elevation={ 0 } position='sticky' ismobile={ 0 }>
            <NormalizeTopbar>
                <Tabs data-test-id='activity-tabs' value={ tab } onChange={ (e, value) => setTab(value) }
                      indicatorColor='primary'>
                    <Tab data-test-id='activity-tab-notifications' label='Notifications'/>
                    <Tab data-test-id='activity-tab-requests' label='Requests'/>
                </Tabs>

                <MarkAllButton size='small' data-test-id='activity-mark-all' variant='outlined' color='primary'
                               onClick={ () => markAllRead() }>Mark all as read</MarkAllButton>
            </NormalizeTopbar>
        </TabBar>

        <ActivityList/>
    </ActivityContext>
}

export default () => {
    return <>
        <ActivityProvider>
            <ActivityScreen/>
        </ActivityProvider>
    </>
}

const ActivityContext = styled(Grid)`
  display: flex;
`

const Separator = styled.hr`
  z-index: 15;
`
const MarkAllButton = styled(Button)`
  align-self: center;
`

const NormalizeTopbar = styled(Container)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const TabBar = styled(AppBar)`
  position: sticky;
  margin-top: -20px; // remove padding
  width: 100%;
  background-color: #ffffff;
  color: ${ ({ theme }) => theme.palette.secondary.main };

`
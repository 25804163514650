import React, { createContext, useContext, useState } from 'react'
import PropTypes from 'prop-types'
import { fetchProfile, resendConfirmationEmail } from '../services/profile.datasource'
import { useAccount } from '../../account'
import useAsyncEffect from '@n1ru4l/use-async-effect'
import { useAlerts } from '../../alerts'
import { useTranslation } from 'react-i18next'
import i18n from '../../../i18n'

const ProfileContext = createContext({})

const ProfileProvider = ({ children }) => {
    const { t } = useTranslation('', i18n)
    const { account, clearData } = useAccount()
    const { showSnack } = useAlerts()
    const [profile, setProfile] = useState()
    const isLoading = !profile

    useAsyncEffect(function* () {
        const profile = yield fetchProfile(account.userId)
        setProfile(profile)
    }, [])

    const logout = () => clearData()

    const resendConfEmail = async () => {
        const emailSent = await resendConfirmationEmail(profile.email)
        if (emailSent) {
            showSnack(t('profile.resendConfirmationSuccess'), 'success')
        } else {
            showSnack(t('profile.resendConfirmationFailure'), 'error')
        }
    }

    return <ProfileContext.Provider value={ { profile, isLoading, logout, resendConfEmail } }>
        { children }
    </ProfileContext.Provider>
}

export const useProfile = () => useContext(ProfileContext)

ProfileProvider.propTypes = {
    children: PropTypes.object
}

export default ProfileProvider
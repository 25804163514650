import { REFRESH_EVENT_DETAILS, SET_EVENT_DETAILS } from '..'

const initialState = {
    data: {},
    error: null
}

export default (state = initialState, action) => {

    switch (action.type) {
        case SET_EVENT_DETAILS: {
            const payload = action.payload

            if (payload.error) {
                return {
                    ...state,
                    error: payload.error
                }
            }

            return {
                data: payload
            }
        }
        case REFRESH_EVENT_DETAILS: {
            return {
                data: {}
            }
        }
        default:
            return state
    }
}
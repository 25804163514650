import { isEmpty } from '../../shared/'

const getEventPermissions = (account, event, openedCalendar) => {
    const originatingCalendarUser = event.originatingCalendar?.userLink

    return {
        isEventOwner: event.owner === account.userId,
        isCalendarOwner: openedCalendar?.isOwner,
        originatingCalendarUser,
        originatingCalendarPermissions: originatingCalendarUser?.permissions ?? {}
    }
}

const canEditEvent = (account, event, openedCalendar) => {
    // I created the event OR
    // I joined the event, I have it in another calendar but I also have the originating calendar with edit permissions OR
    // I have the originating calendar with edit permissions
    const { isEventOwner, isCalendarOwner, originatingCalendarPermissions } = getEventPermissions(account, event, openedCalendar)
    return isEventOwner || (isCalendarOwner && originatingCalendarPermissions.canEdit) || originatingCalendarPermissions.canEdit
}

const getRemovalAction = (account, event, openedCalendar) => {
    const { isEventOwner, originatingCalendarPermissions } = getEventPermissions(account, event, openedCalendar)
    const openedCalendarPermissions = openedCalendar?.permissions ?? {}

    if (isEventOwner || originatingCalendarPermissions.canEdit) {
        return 'delete'
    }

    return openedCalendarPermissions.canEdit ? 'remove' : ''
}

const getCalendarIdFromEvent = ({ originatingCalendar }) => {
    // this is populated only if user is shared the calendar
    const userLink = originatingCalendar?.userLink

    if (!isEmpty(userLink)) {
        return originatingCalendar.calendarId
    }

    return null
}

const buildEventUrl = (name, id) => {
    return `/events/${ encodeURIComponent(name.replace(/-/g, ' ').replace(/\s+/g, '_')) }-${ id }`
}

export {
    canEditEvent,
    buildEventUrl,
    getRemovalAction,
    getCalendarIdFromEvent
}
import moment from 'moment-timezone'
import useFormattedMoment from '../hooks/useFormattedMoment'
import humanizeDuration from 'humanize-duration'
import supportedTimezones from './supportedTimezones'

export const formattedMoment = useFormattedMoment(moment, new Date())

export const isToday = (day) => day.toLowerCase().indexOf('today') !== -1

export const formatForDates = () => ({
    sameDay: '[Today], MMMM D | dddd | YYYY',
    nextDay: '[Tomorrow], MMMM D | dddd | YYYY',
    lastDay: '[Yesterday], MMMM D | dddd | YYYY'
})

export const getEventLocalTime = (startDate, endDate) => {
    let formattedStartDate = moment(startDate).tz(moment.tz.guess()).format('hh:mm A')
    let formattedEndDate = `${moment(endDate).tz(moment.tz.guess()).format('hh:mm A z')} ( Local )`
    return `${formattedStartDate} - ${formattedEndDate}`
}

export const getEventTimeBasedOnTz = (startDate, endDate, startTimezone, endTimezone) => {
    let formattedStartDate = moment(startDate).tz(startTimezone).format('hh:mm A z')
    let formattedEndDate = moment(endDate).tz(endTimezone).format('hh:mm A z')
    return `${formattedStartDate} - ${formattedEndDate}`
}

export const formatDateUserFriendly = (date, timezone) => {
    return formattedMoment(date).tz(timezone).calendar(null, formatForDates())
}

export const formatDate = (date, timezone, format) => {
    return moment(date).tz(timezone).format(format)
}

export const humanizeSeconds = (duration) => {
    return humanizeDuration(duration)
}

export const formattedStartAndEndDates = (startDate, endDate, startTimezone, endTimezone, isMultiDay) => {

    let startDateDiff = Math.round(moment(startDate).tz(startTimezone).diff(new Date(), 'days', true))
    let endDateDiff = Math.round(moment(startDate).tz(startTimezone).diff(new Date(), 'days', true))
    let formattedStartDate
    let formattedEndDate
    if (isMultiDay) {
        formattedStartDate = startDateDiff === 0 || startDateDiff === 1 ?
            formatDateUserFriendly(startDate, startTimezone) :
            formatDate(startDate, startTimezone, 'MMMM D, YYYY | dddd')
        formattedEndDate = endDateDiff === 0 || endDateDiff === 1 ?
            formatDateUserFriendly(endDate, endTimezone) :
            formatDate(endDate, endTimezone, 'MMMM D, YYYY | dddd')
        return formattedStartDate + ' to ' + formattedEndDate
    } else {
        return startDateDiff === 0 || startDateDiff === 1 ?
            formatDateUserFriendly(startDate, startTimezone) :
            formatDate(startDate, startTimezone, 'MMMM D, YYYY | dddd')
    }
}

export const isSameYear = (year) => {
    return year && year.trim() === moment().format('YYYY') ? true : false

}

export const groupEventByDate = (events) => {

    let futureAnchor = false
    return events && events.reduce((acc, item) => {
        let rawDiff = moment(item.startDate).diff(moment(), 'days', true)
        let diff = moment(item.startDate).diff(moment(), 'days', true)
        let key = diff >= -1 && diff <= 2 ? formatDateUserFriendly(item.startDate, moment.tz.guess()) :
            formatDate(item.startDate, moment.tz.guess(), 'MMMM D | dddd | YYYY')

        if (!acc.items[key])
            acc.items[key] = []

        item.diff = diff
        acc.items[key].push(item)

        if (!futureAnchor && rawDiff >= 0) {
            acc.meta.initialAnchorIndex = Object.keys(acc.items).length - 1
            futureAnchor = true
        }

        return acc

    }, { meta: {}, items: {} })
}

export const getPastFetchAnchorItem = (events, pastEventFetchAnchorKey) => {

    return events && events.reduce((acc, item) => {

        let diff = Math.round(moment(item.startDate).tz(item.startTimezone).diff(new Date(), 'days', true))
        let key = diff === 0 || diff === 1 ?
            formatDateUserFriendly(item.startDate, item.startTimezone) :
            formatDate(item.startDate, item.startTimezone, 'MMMM D | dddd | YYYY')

        if (!acc.items[key]) acc.items[key] = []
        item.diff = diff
        acc.items[key].push(item)

        if (pastEventFetchAnchorKey) {
            if (key === pastEventFetchAnchorKey) {
                acc.indexToAnchor = Object.keys(acc.items).length - 1
            }
        }

        return acc

    }, { indexToAnchor: 0, items: {} })

}

export const getTimezoneAbbreviation = (timezone) => {
    let abbr = moment.tz(timezone).format('z')
    // Treat weird abbreviation such as GMT+05:45
    if (abbr.length === 5) {
        abbr = abbr.slice(0, abbr.length - 2) + ':' + abbr.slice(abbr.length - 2)
    }
    return /([0-9])/.test(abbr) ? 'GMT' + abbr : abbr
}

export const getSupportedTimezones = () => {
    const momentTimezones = moment.tz.names()
    return momentTimezones.filter(tmz => !!supportedTimezones.includes(tmz))
}

export const isMultiDay = event => {
    if (!event) {
        return false
    }

    if (event.isAllDay) {
        let diff = moment(event.endDate).diff(event.startDate, 'minutes')
        let minutesInOneDay = 60 * 24
        let dayDiff = Math.ceil(diff / minutesInOneDay)

        return dayDiff > 1
    }

    return moment(event.endDate).format('YYYY-MM-DD') !== moment(event.startDate).format('YYYY-MM-DD')
}

export const convertDateToUTC = (date, timezone) => {
    const offset = moment(date).tz(timezone).format().substr(19)
    const formattedDate = moment(date).format().substr(0, 19).concat(offset)
    return moment.utc(formattedDate).toISOString()
}
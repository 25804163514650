import React from 'react'
import styled from 'styled-components'
import Typography from '@material-ui/core/Typography'
import ClockIcon from '../../shared/assets/ClockIcon'
import EventItemWrapper from './EventItemWrapper'
import PropTypes from 'prop-types'
import { getEventLocalTime, getEventTimeBasedOnTz } from '../../shared/utils/dateUtils'

function EventTime({ startDate, endDate, startTimezone, endTimezone }) {

    let localTime = getEventLocalTime(startDate, endDate)
    let useTimeZoneTime = getEventTimeBasedOnTz(startDate, endDate, startTimezone, endTimezone)

    return (
        <EventItemWrapper icon={<ClockIcon widht={16} height={16}></ClockIcon>} >
            <EventTimeText>
                {startDate &&
                    <TextContainer
                        noWrap={true}
                        color='textPrimary'
                        gutterBottom={true}> {useTimeZoneTime}
                    </TextContainer>}
                {startDate &&
                    <TextContainer
                        noWrap={true}
                        color='textPrimary'
                        gutterBottom={true}> {localTime}
                    </TextContainer>}
            </EventTimeText>
        </EventItemWrapper>
    )
}

EventTime.propTypes = {
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    startTimezone: PropTypes.string,
    endTimezone: PropTypes.string
}

const TextContainer = styled(Typography)`
  ${ ({ theme }) => `
      font-size : ${ theme.spacing(2)}px;
      font-weight : 500;
       
  ` };
`
const EventTimeText = styled.div`
    display : flex;
    flex-direction : column
`
export default EventTime

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { FormikFieldGroup } from '../../shared'
import { useTranslation } from 'react-i18next'
import DescriptionIcon from '@material-ui/icons/Description'
import i18n from '../../../i18n'

const CreateEditEventDescription = ({ handleBlur }) => {
    const { t } = useTranslation('', i18n)

    return <CreateEditEventDescriptionWrapper>
        <StyledDescriptionIcon data-test-id='event-description-icon' />
        <DescriptionWrapper>
            <Description
                name='description'
                placeholder={ t('event.description.text') }
                multiline
                rows={ 5 }
                fullWidth
                onBlur={ () => handleBlur('description', true, false) }
                data-test-id='create-edit-event-description-text'
            />
        </DescriptionWrapper>
    </CreateEditEventDescriptionWrapper>
}

const CreateEditEventDescriptionWrapper = styled.div`
    display: flex;
    margin-top: 40px;
`

const StyledDescriptionIcon = styled(DescriptionIcon)`
    height: 20px;
    width: 20px;
`

const DescriptionWrapper = styled.div`
    flex: 1;
    margin-left: 6px;
`

const Description = styled(FormikFieldGroup)`
    background: ${({ theme }) => theme.palette.background.default };
    line-height: 1.36;
    padding: 20px;
`

CreateEditEventDescription.propTypes = {
    handleBlur: PropTypes.func
}

export default CreateEditEventDescription 
import React from 'react'
import PropTypes from 'prop-types'
import { Formik, Form } from 'formik'
import { Container, Button, Typography } from '@material-ui/core'
import styled from 'styled-components'
import { useAccount, Header, Footer } from '..'
import { useTranslation } from 'react-i18next'
import i18n from '../../../i18n'
import { FormikFieldGroup, FormikPasswordField, getClassicAppUrl, EMAIL_REGEX } from '../../shared'

const LoginComponent = ({ isSmallMobile, isLandscapeMobile }) => {
    const { handleLoginSubmit } = useAccount()
    const { t } = useTranslation('', i18n)

    const validate = values => {
        const errors = {}

        if (!values.email) {
            errors.email = t('loginScreen.error.emailRequired')
        } else if (!EMAIL_REGEX.test(values.email)) {
            errors.email = t('loginScreen.error.emailInvalid')
        }

        if (!values.password) {
            errors.password = t('passwordValidations.passwordRequired')
        }

        return errors
    }

    return <>
        <Header isSmallMobile={ isSmallMobile }
            isLandscapeMobile={ isLandscapeMobile }
            title={ t('loginScreen.title') } />

        <Formik
            initialValues={ {
                email: '',
                password: ''
            } }
            validate={ validate }
            onSubmit={ handleLoginSubmit }
        >
            <FormContainer issmallmobile={ isSmallMobile ? 1 : 0 }>
                <Form>
                    <FormikFieldGroup
                        label='Email'
                        name='email'
                        type='text'
                        placeholder='johndoe@gmail.com'
                        elevation={ 2 }
                    />
                    <FormikPasswordField label={ t('loginScreen.password') } />

                    <ForgotPassword
                        component='a'
                        href={ `${ getClassicAppUrl() }/reset-password` }
                        color='primary'
                        data-test-id='forgot-password-link'>
                        { t('loginScreen.forgotPassword') }</ForgotPassword>
                    <LogInButton
                        color='primary'
                        type='submit'
                        size='small'
                        variant='contained'
                        data-test-id='log-in-btn'
                        islandscapemobile={ isLandscapeMobile ? 1 : 0 }>{ t('loginScreen.loginBtn') }</LogInButton>
                </Form>
            </FormContainer>
        </Formik>

        <Footer
            isSmallMobile={ isSmallMobile }
            text={ t('loginScreen.createAccount.text') }
            linkText={ t('loginScreen.createAccount.link') }
            goToPath='register'
        />
    </>
}

const FormContainer = styled(Container)`
    max-width: ${ ({ issmallmobile }) => issmallmobile ? 'unset' : '368px' };
`

const ForgotPassword = styled(Typography)`
    cursor: pointer;    
    float: right;
    font-size: 15px;
    text-decoration: none;
`

const LogInButton = styled(Button)`
    margin-top: ${ ({ islandscapemobile }) => islandscapemobile ? '10px' : '50px' };
    width: 100%;
`

LoginComponent.propTypes = {
    isSmallMobile: PropTypes.bool,
    isLandscapeMobile: PropTypes.bool
}

export default LoginComponent
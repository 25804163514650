import { createMuiTheme } from '@material-ui/core'

const theme = createMuiTheme({
    palette: {
        type: 'light',
        primary: {
            main: '#0075b8',
            contrastText: '#fff'
        },
        secondary: {
            main: 'rgba(27, 31, 33, .4)',
            contrastText: '#000',
            default: '#1b1f21'
        },
        secondaryAccent: {
            positive: '#3dab38',
            positiveHover: '#3dab3822',
            negative: '#b83200',
            negativeHover: '#b8320022'
        },
        text: {
            primary: '#1b1f21',
            secondary: '#0075b8',
            disabled: '#A4A6A7',
            highlight: '#127AB3'
        },
        background: {
            paper: '#FFF',
            default: '#f7f9fa',
            label: '#dfeaf0'
        },
        success: {
            main: '#3dab38'
        },
        info: {
            main: '#0075b8'
        },
        spacing: '8px',
        divider: '#dfeaf0'
    },
    overrides: {
        MuiTab: {
            root: {
                color: '#202020',
                fontSize: '16px',
                fontWeight: 500,
                minHeight: '40px',
                textTransform: 'capitalize',
                '&$textColorInherit': {
                    color: '#202020',
                    opacity: 1,
                },
                '&$selected': {
                    color: '#0075b8',
                    fontWeight: 700,
                },
                '@media (min-width: 600px)': {
                    minWidth: '50px',
                }
            },
        },
        MuiAppBar: {
            positionSticky: {
                top: '72px',
            }
        },
        MuiCssBaseline: {
            '@global': {
                body: {
                    height: '100%',
                }
            }
        },
        MuiButton: {
            startIcon: {
                marginLeft: 0,
                marginRight: 0,
            },
        },
    },
    typography: {
        fontFamily: [
            'Alegreya Sans',
            'sans-serif'
        ].join(',')
    }
})

export default theme
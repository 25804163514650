import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { Grid, ListItem, ListItemText, Switch, useMediaQuery } from '@material-ui/core'

const SettingCard = ({ title, description, name, checked, onChange }) => {
    const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'))

    return (
        <StyledListItem data-test-id={`setting-card-${name}`} ismobile={isMobile ? 1 : 0}>

            <StyledGrid container direction={'column'} >
                <StyledSettingTitle
                    primary={title}
                    data-test-id='setting-card-title' />

                <StyledSettingDescription
                    primary={description}
                    data-test-id='setting-card-description'
                />
            </StyledGrid >

            <Switch
                edge='end'
                color='primary'
                onChange={onChange}
                checked={checked}
                inputProps={{ 'aria-labelledby': `switch-to-${title}` }}
                data-test-id='setting-card-switch'
            />

        </StyledListItem >
    )
}

const StyledListItem = styled(ListItem)`
    background-color: ${ ({ theme }) => theme.palette.background.default};
    border-radius: 4px;
    margin-bottom: 20px;
    ${({ ismobile }) => {
        return ismobile ?
            { 'height': 'unset' }
            : { 'height': '100px', 'padding-right': '40px', 'padding-left': '40px' }
    }}
`

const StyledSettingTitle = styled(ListItemText)`
    font-size: 22px;
    flex: unset;
    margin-right: 16px;
`

const StyledSettingDescription = styled(ListItemText)`
    font-size: 18px;
    flex: unset;
    opacity: 0.4;
`

const StyledGrid = styled(Grid)`
    margin-right: 16px;
`

SettingCard.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    name: PropTypes.string,
    checked: PropTypes.bool,
    onChange: PropTypes.func
}


export default SettingCard
import React from 'react'
import PropTypes from 'prop-types'
import { Button, Dialog, DialogActions, DialogTitle } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import i18n from '../../../i18n'

const ConfirmationDialog = (props) => {
    const { t } = useTranslation('', i18n)
    const { open, title, onClose, confirm } = props

    return <Dialog onClose={ onClose } aria-labelledby="cancel-dialog" open={ open }>
        <DialogTitle>
            { title }
        </DialogTitle>
        <DialogActions>
            <Button onClick={ confirm } color="primary" data-test-id="confirm-changes">
                { t('yes') } </Button>
            <Button onClick={ onClose } color="primary" data-test-id="discard-changes">
                { t('no') } </Button>
        </DialogActions>
    </Dialog>
}

ConfirmationDialog.propTypes = {
    open: PropTypes.bool,
    title: PropTypes.string,
    onClose: PropTypes.func,
    confirm: PropTypes.func
}

export default ConfirmationDialog